import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReaderContentProps } from './types/ReaderControlsTypes';

export const ReaderContent: React.FC<ReaderContentProps> = ({
  story,
  isDarkTheme,
  isUppercase,
  fontSize
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex-1 overflow-y-auto custom-scrollbar">
      <div 
        className="mx-auto"
        style={{ fontSize: `${fontSize}px` }}
      >
        {/* Container principal du contenu */}
        <div className={`
          rounded-xl p-4 md:p-6
          ${isDarkTheme 
            ? 'bg-black/90 border-white/10' 
            : 'bg-white border-gray-200'} // Fond blanc plein
          backdrop-blur-sm border
        `}>
          {story.details.chapters?.map((chapter, index) => (
            <div key={index} className="mb-8 last:mb-0">
              <h3 className={`
                text-2xl font-bold mb-4
                ${isDarkTheme ? 'text-white' : 'text-gray-900'}
                ${isUppercase ? 'uppercase' : ''}
                tracking-tight
              `}>
                {chapter.title}
              </h3>
              
              <p className={`
                leading-relaxed
                ${isDarkTheme ? 'text-white/90' : 'text-gray-800'}
                ${isUppercase ? 'uppercase' : ''}
                whitespace-pre-line
              `}>
                {chapter.content}
              </p>

              {story.details.chapters && index < story.details.chapters.length - 1 && (
                <div className={`
                  my-8 h-px w-1/3 mx-auto
                  ${isDarkTheme ? 'bg-white/10' : 'bg-black/10'}
                `} />
              )}
            </div>
          ))}
        </div>

        {/* Bouton continuer */}
        <div className="flex justify-center my-8">
          <button
            disabled={true}
            className={`
              px-8 py-4 rounded-lg
              bg-gradient-to-r from-purple-500 to-blue-500
              hover:from-purple-600 hover:to-blue-600
              disabled:from-gray-500 disabled:to-gray-600
              text-white font-medium
              shadow-lg
              transition-all duration-300
              disabled:opacity-50 disabled:cursor-not-allowed
              transform hover:scale-[1.02] active:scale-[0.98]
            `}
          >
            {t('ui.continue_story')} 
            <i className="ml-2 opacity-75">{t('general.coming_soon')}</i>
          </button>
        </div>
      </div>
    </div>
  );
};
