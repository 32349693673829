import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  Gamepad, 
  Clock, 
  Star,
  Languages,
  BookMarked,
  Heart,
  GraduationCap,
  Puzzle,
  Feather
} from 'lucide-react';
import { Story } from '../../types/Story';

const StoryIllustrationDefault = '../../img/logo.webp';

const StoryCard: React.FC<{ story: Story }> = ({ story }) => {
  const { t } = useTranslation();

  const truncateSummary = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className="group relative rounded-xl transition-all duration-300 hover:transform hover:scale-[1.02]">
      {story.details.settings.isInteractive ? (
        <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/30 via-purple-500/30 to-pink-500/30 rounded-xl blur-md -m-1 animate-gradient" />
      ) : (
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 via-pink-500/10 to-blue-500/10 rounded-xl blur-sm -m-1" />
      )}

      <div className={`
        relative backdrop-blur-sm rounded-xl border overflow-hidden
        ${story.details.settings.isInteractive 
          ? 'theme-bg/40 border-purple-500/30' 
          : 'theme-card border-white/10'}
      `}>
        <div className="h-48 relative">
          <img 
            src={story.details.illustration_file_url || StoryIllustrationDefault} 
            alt={story.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />

          <div className="absolute top-4 left-4 right-4 flex justify-between">
            <div className="flex items-center gap-2">
              {story.details.settings.isInteractive && (
                <div className="flex items-center gap-2 px-3 py-1.5 bg-purple-500/30 rounded-full backdrop-blur-sm">
                  <Gamepad size={16} className="text-purple-300" />
                  <span className="text-sm font-medium text-purple-200">
                    {t('stories_page.badges.interactive')}
                  </span>
                </div>
              )}
            </div>
            
            {story.rating && (
              <div className="flex items-center gap-1.5 px-3 py-1.5 bg-yellow-500/20 rounded-full backdrop-blur-sm">
                <Star size={16} className="text-yellow-400 fill-yellow-400" />
                <span className="text-sm font-medium text-yellow-200">
                  {story.rating.average.toFixed(1)}
                </span>
              </div>
            )}
          </div>

          <div className="absolute bottom-4 left-4 right-4 flex justify-between items-center">
            <div className="flex items-center gap-2 px-3 py-1.5 theme-bg/40 rounded-full backdrop-blur-sm">
              <Languages size={16} className="theme-text/70" />
              <span className="text-sm uppercase">
                {story.details.settings.language}
              </span>
            </div>
            
            {story.details.settings.isInteractive && story.details.settings.difficulty && (
              <span className={`
                px-3 py-1.5 rounded-full text-sm font-medium backdrop-blur-sm
                ${story.details.settings.difficulty === 'easy' ? 'bg-green-500/20 text-green-300' :
                  story.details.settings.difficulty === 'medium' ? 'bg-yellow-500/20 text-yellow-300' :
                  'bg-red-500/20 text-red-300'}
              `}>
                {t(`stories_page.badges.difficulty.${story.details.settings.difficulty}`)}
              </span>
            )}
          </div>
        </div>

        <div className="p-6">
          <div className="mb-4">
            <h3 className="text-xl font-bold mb-2">{story.title}</h3>
            <p className="theme-text/70 text-sm line-clamp-4">{story.summary && truncateSummary(story.summary, 200)}</p>
          </div>

          <div className="flex items-center gap-4 text-sm theme-text-secondary mb-4">
            {story.details.settings.duration && (
              <div className="flex items-center gap-1">
                <Clock size={16} />
                <span>
                  {story.details.settings.duration} {t('stories_page.card.minutes')}
                </span>
              </div>
            )}
            {story.details.settings.isInteractive && story.details.settings.ageRange && (
              <div>
                <span>
                  {story.details.settings.ageRange} {t('stories_page.card.years')}
                </span>
              </div>
            )}
            {story.details.theme && (
              <div className="flex items-center gap-1">
                <BookMarked size={16} />
                <span>{t(`options.themes.${story.details.theme}`)}</span>
              </div>
            )}
            {story.details.tone && (
              <div className="flex items-center gap-1">
                <Feather size={16} />
                <span>{t(`options.tones.${story.details.tone}`)}</span>
              </div>
            )}
          </div>

          {story.details.moral_values && story.details.moral_values.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-4">
              <Heart size={16} />
              {story.details.moral_values.slice(0, 3).map((value, index) => (
                <span
                  key={index}
                  className="px-2 py-1 bg-white/5 rounded-full text-xs theme-text/70"
                >
                  {t(`options.moralValues.${value}`)}
                </span>
              ))}
              {story.details.moral_values.length > 3 && (
                <span className="px-2 py-1 bg-white/5 rounded-full text-xs theme-text/70">
                  +{story.details.moral_values.length - 3}
                </span>
              )}
            </div>
          )}

          {story.details.educational_elements && story.details.educational_elements.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-4">
              <GraduationCap size={16} />
              {story.details.educational_elements.slice(0, 3).map((value, index) => (
                <span
                  key={index}
                  className="px-2 py-1 bg-white/5 rounded-full text-xs theme-text/70"
                >
                  {t(`options.educationalElements.${value}`)}
                </span>
              ))}
              {story.details.educational_elements.length > 3 && (
                <span className="px-2 py-1 bg-white/5 rounded-full text-xs theme-text/70">
                  {t('stories_page.card.more_items', { count: story.details.educational_elements.length - 3 })}
                </span>
              )}
            </div>
          )}

          <Link
            to={`/story/${story.uuid}`}
            className={`
              w-full px-4 py-2 rounded-lg transition-all text-center
              ${story.details.settings.isInteractive 
                ? 'bg-purple-500/20 hover:bg-purple-500/30 text-purple-200' 
                : 'bg-white/10 hover:bg-white/20 theme-text'}
            `}
          >
            {story.details.settings.isInteractive 
              ? t('stories_page.card.start') 
              : t('stories_page.card.read')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StoryCard;