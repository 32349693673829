import React from 'react';
import Select from 'react-select';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../ThemeProvider/ThemeProvider';

const languageOptions = [
  { value: 'fr', label: <>🇫🇷 Français</> },
  { value: 'en', label: <>🇬🇧 English</> },
  { value: 'es', label: <>🇪🇸 Español</> },
  { value: 'pt', label: <>🇵🇹 Português</> },
  { value: 'de', label: <>🇩🇪 Deutschland</> },
  { value: 'it', label: <>🇮🇹 Italian</> },
  { value: 'ja', label: <>🇯🇵 日本語</> },
  { value: 'cn', label: <>🇨🇳 中国人</> }
];

export const LanguageSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  
  const handleChange = (selectedOption: any) => {
    const languageCode = selectedOption.value;
    i18n.changeLanguage(languageCode);
  };

  const i18nLanguage = i18n.language.length > 2 ? i18n.language.substring(0, 2) : i18n.language;

  const getSelectStyles = () => {
    const isDark = theme === 'dark';
    
    const colors = {
      background: isDark ? 'rgba(23, 23, 23, 0.95)' : 'rgba(255, 255, 255, 0.95)',
      text: isDark ? '#ffffff' : '#000000',
      textSecondary: isDark ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
      border: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      borderHover: isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
      focusColor: 'rgba(139, 92, 246, 0.5)',
      hoverBg: isDark ? 'rgba(139, 92, 246, 0.2)' : 'rgba(139, 92, 246, 0.1)',
      activeBg: isDark ? 'rgba(139, 92, 246, 0.3)' : 'rgba(139, 92, 246, 0.2)',
    };

    return {
      control: (base: any, state: any) => ({
        ...base,
        background: colors.background,
        borderColor: colors.border,
        borderRadius: '0.5rem',
        boxShadow: state.isFocused ? `0 0 0 1px ${colors.focusColor}` : 'none',
        '&:hover': {
          borderColor: colors.borderHover
        }
      }),
      menu: (base: any) => ({
        ...base,
        background: colors.background,
        backdropFilter: 'blur(10px)',
        border: `1px solid ${colors.border}`,
        borderRadius: '0.5rem',
        boxShadow: `0 4px 6px -1px rgba(0, 0, 0, 0.1)`,
        overflow: 'hidden'
      }),
      menuList: (base: any) => ({
        ...base,
        padding: '0.5rem'
      }),
      option: (base: any, state: any) => ({
        ...base,
        backgroundColor: state.isFocused ? colors.hoverBg : 'transparent',
        color: colors.text,
        padding: '0.5rem 1rem',
        borderRadius: '0.375rem',
        cursor: 'pointer',
        '&:active': {
          backgroundColor: colors.activeBg
        },
        '&:hover': {
          backgroundColor: colors.hoverBg
        }
      }),
      singleValue: (base: any) => ({
        ...base,
        color: colors.text
      }),
      input: (base: any) => ({
        ...base,
        color: colors.text
      }),
      placeholder: (base: any) => ({
        ...base,
        color: colors.textSecondary
      }),
      indicatorSeparator: (base: any) => ({
        ...base,
        backgroundColor: colors.border
      }),
      dropdownIndicator: (base: any) => ({
        ...base,
        color: colors.textSecondary,
        '&:hover': {
          color: colors.text
        }
      })
    };
  };

  return (
    <Select
      options={languageOptions}
      onChange={handleChange}
      placeholder={t('select_language')}
      defaultValue={languageOptions.find(option => option.value === i18nLanguage)}
      className="language-select"
      classNamePrefix="language-select"
      menuPlacement="top"
      styles={getSelectStyles()}
      isSearchable={false}
    />
  );
};