import { useNavigate } from 'react-router-dom';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { useEffect, useState } from 'react';
import { Story } from '../types/Story';
import { getAllStories } from '../api/stories';
import { fromApi } from '../utils/parsedStory';
import { useTranslation } from 'react-i18next';
import { TextLoading } from '../components/Loading/TextLoading';
import { 
  Gamepad, 
  Clock, 
  Search, 
  Filter, 
  Star,
  Languages,
  SortDesc,
  BookOpen,
  Heart,
  GraduationCap,
  BookMarked,
  Feather
} from 'lucide-react';
import { languagesOptions } from '../utils/variables';
import StoryCard from '../components/StoryCard/StoryCard';
import { Layout } from '../components/Layout/Layout';

interface Filters {
  searchQuery: string;
  difficulty?: string;
  ageRange?: string;
  language?: string;
  isInteractive?: boolean;
  minDuration?: number;
  maxDuration?: number;
  minRating?: number;
  tone?: string;
  theme?: string;
  elements?: string[];
  values?: string[];
  sortBy: 'recent' | 'rating' | 'duration' | 'popularity';
  sortOrder: 'asc' | 'desc';
}

export const StoriesPage = () => {
  const { t } = useTranslation();
  const [stories, setStories] = useState<Story[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilters, setShowFilters] = useState(false);
  const [availableThemes, setAvailableThemes] = useState<string[]>([]);
  const [availableTones, setAvailableTones] = useState<string[]>([]);
  const [availableElements, setAvailableElements] = useState<string[]>([]);
  const [availableValues, setAvailableValues] = useState<string[]>([]);
  
  const [filters, setFilters] = useState<Filters>({
    searchQuery: '',
    sortBy: 'recent',
    sortOrder: 'desc'
  });

  useEffect(() => {
    const fetchStories = async () => {
      try {
        setLoading(true);
        const stories: any = await getAllStories();
        const parsedStories: Story[] = stories.map((story: any) => fromApi(story));
        setStories(parsedStories);
        
        const themes = new Set<string>();
        const tones = new Set<string>();
        const elements = new Set<string>();
        const values = new Set<string>();
        parsedStories.forEach(story => {
          story.details.theme && themes.add(story.details.theme);
          story.details.tone && tones.add(story.details.tone);
          Array.isArray(story.details.educational_elements) && story.details.educational_elements?.forEach(elmt => elements.add(elmt));
          Array.isArray(story.details.moral_values) && story.details.moral_values?.forEach(value => values.add(value));
        });
        setAvailableThemes(Array.from(themes));
        setAvailableTones(Array.from(tones));
        setAvailableElements(Array.from(elements));
        setAvailableValues(Array.from(values));
      } catch (error) {
        console.error('Error fetching stories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  const sortStories = (stories: Story[]) => {
    return [...stories].sort((a, b) => {
      let comparison = 0;
      switch (filters.sortBy) {
        case 'rating':
          comparison = (b.rating?.average || 0) - (a.rating?.average || 0);
          break;
        case 'duration':
          comparison = (b.details.settings.duration || 0) - (a.details.settings.duration || 0);
          break;
        case 'popularity':
          comparison = (b.rating?.count || 0) - (a.rating?.count || 0);
          break;
        case 'recent':
        default:
          comparison = new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }
      return filters.sortOrder === 'desc' ? comparison : -comparison;
    });
  };

  const filteredStories = stories?.filter(story => {
    const matchesSearch = story.title.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
    story.summary && story.summary.toLowerCase().includes(filters.searchQuery.toLowerCase());
    const matchesDifficulty = !filters.difficulty || story.details.settings.difficulty === filters.difficulty;
    const matchesAgeRange = !filters.ageRange || story.details.settings.ageRange === filters.ageRange;
    const matchesLanguage = !filters.language || story.details.settings.language === filters.language;
    const matchesInteractive = filters.isInteractive === undefined || story.details.settings.isInteractive === filters.isInteractive;
    const matchesDuration = (!filters.minDuration || story.details.settings.duration && story.details.settings.duration >= filters.minDuration) &&
                           (!filters.maxDuration || story.details.settings.duration && story.details.settings.duration <= filters.maxDuration);
    const matchesRating = !filters.minRating || (story.rating?.average || 0) >= filters.minRating;
    const matchesThemes = !filters.theme || filters.theme === story.details.theme;
    const matchesValues = !filters.values?.length || 
                         story.details.moral_values?.some(value => filters.values?.includes(value));
    
    return matchesSearch && matchesDifficulty && matchesAgeRange && matchesLanguage && 
           matchesInteractive && matchesDuration && matchesRating && matchesThemes && matchesValues;
  });

  const sortedAndFilteredStories = filteredStories ? sortStories(filteredStories) : [];

  return (
    <Layout>
      <div className="min-h-screen theme-bg theme-text relative overflow-x-hidden">
        <div className="fixed inset-0 opacity-30">
          <div className="absolute top-0 right-0 w-[600px] h-[600px] rounded-full bg-purple-500/20 blur-[150px] mix-blend-screen" />
          <div className="absolute bottom-0 left-0 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[150px] mix-blend-screen" />
        </div>

        <div className="relative z-10 max-w-7xl mx-auto p-8">
          <div className="mb-8 space-y-4">
            <div className="flex items-center justify-between">
              <h1 className="text-4xl font-bold">
                {loading ? 
                  <TextLoading text={t('loading_states.loading_adventures')} variant='typewriter' /> : 
                  t('stories_page.catalog.title')
                }
              </h1>
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center gap-2 px-4 py-2 bg-white/10 rounded-lg hover:bg-white/20 transition-all"
              >
                <Filter size={20} />
                {t('ui.advanced_filters')}
              </button>
            </div>

            <div className="flex gap-4">
              <div className="flex-1 relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 theme-text/40" size={20} />
                <input
                  type="text"
                  placeholder={t('ui.search_placeholder')}
                  className="w-full pl-10 pr-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-purple-500/50"
                  value={filters.searchQuery}
                  onChange={(e) => setFilters({ ...filters, searchQuery: e.target.value })}
                />
              </div>
              
              <div className="flex gap-2">
                <select
                  className="px-4 py-2 theme-card border border-white/10 rounded-lg"
                  value={filters.sortBy}
                  onChange={(e) => setFilters({ ...filters, sortBy: e.target.value as Filters['sortBy'] })}
                >
                  <option value="recent">{t('stories_page.sort.most_recent')}</option>
                  <option value="rating">{t('stories_page.sort.best_rated')}</option>
                  <option value="duration">{t('stories_page.sort.duration')}</option>
                  <option value="popularity">{t('stories_page.sort.popularity')}</option>
                </select>
                <button
                  onClick={() => setFilters({ 
                    ...filters, 
                    sortOrder: filters.sortOrder === 'desc' ? 'asc' : 'desc' 
                  })}
                  className="p-2 theme-card border border-white/10 rounded-lg hover:bg-white/10"
                >
                  <SortDesc size={20} className={filters.sortOrder === 'asc' ? 'rotate-180' : ''} />
                </button>
              </div>
            </div>

            {showFilters && (
              <div className="bg-white/5 rounded-lg p-6 space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <select
                    className="px-4 py-2 theme-card border border-white/10 rounded-lg"
                    value={filters.difficulty}
                    onChange={(e) => setFilters({ ...filters, difficulty: e.target.value || undefined })}
                  >
                    <option value="">{t('stories_page.filters.difficulty_levels.all')}</option>
                    <option value="easy">{t('stories_page.filters.difficulty_levels.easy')}</option>
                    <option value="medium">{t('stories_page.filters.difficulty_levels.medium')}</option>
                    <option value="hard">{t('stories_page.filters.difficulty_levels.hard')}</option>
                  </select>

                  <select
                    className="px-4 py-2 theme-card border border-white/10 rounded-lg"
                    value={filters.ageRange}
                    onChange={(e) => setFilters({ ...filters, ageRange: e.target.value || undefined })}
                  >
                    <option value="">{t('stories_page.filters.age_ranges.all')}</option>
                    <option value="3-5">{t('stories_page.filters.age_ranges.3-5')}</option>
                    <option value="6-8">{t('stories_page.filters.age_ranges.6-8')}</option>
                    <option value="9-12">{t('stories_page.filters.age_ranges.9-12')}</option>
                  </select>

                  <select
                    className="px-4 py-2 theme-card border border-white/10 rounded-lg"
                    value={filters.language}
                    onChange={(e) => setFilters({ ...filters, language: e.target.value || undefined })}
                  >
                    <option value="">{t('ui.select_language')}</option>
                    {languagesOptions.map(lang => (
                      <option key={lang.value} value={lang.value}>{lang.label}</option>
                    ))}
                  </select>

                  <select
                    className="px-4 py-2 theme-card border border-white/10 rounded-lg"
                    value={filters.isInteractive === undefined ? '' : filters.isInteractive.toString()}
                    onChange={(e) => setFilters({ 
                      ...filters, 
                      isInteractive: e.target.value === '' ? undefined : e.target.value === 'true'
                    })}
                  >
                    <option value="">{t('stories_page.filters.story_types.all')}</option>
                    <option value="true">{t('stories_page.filters.story_types.interactive')}</option>
                    <option value="false">{t('stories_page.filters.story_types.classic')}</option>
                  </select>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label className="text-sm theme-text-secondary">
                      {t('stories_page.catalog.duration_minutes')}
                    </label>
                    <div className="flex gap-4">
                      <input
                        type="number"
                        placeholder={t('stories_page.catalog.min')}
                        className="w-24 px-3 py-2 theme-card border border-white/10 rounded-lg"
                        value={filters.minDuration || ''}
                        onChange={(e) => setFilters({ ...filters, minDuration: e.target.value ? Number(e.target.value) : undefined })}
                      />
                      <input
                        type="number"
                        placeholder={t('stories_page.catalog.max')}
                        className="w-24 px-3 py-2 theme-card border border-white/10 rounded-lg"
                        value={filters.maxDuration || ''}
                        onChange={(e) => setFilters({ ...filters, maxDuration: e.target.value ? Number(e.target.value) : undefined })}
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm theme-text-secondary">
                      {t('stories_page.catalog.minimum_rating')}
                    </label>
                    <select
                      className="w-full px-4 py-2 theme-card border border-white/10 rounded-lg"
                      value={filters.minRating || ''}
                      onChange={(e) => setFilters({ ...filters, minRating: e.target.value ? Number(e.target.value) : undefined })}
                    >
                      <option value="">{t('stories_page.catalog.all_ratings')}</option>
                      <option value="4">4 {t('stories_page.catalog.stars_plus')}</option>
                      <option value="3">3 {t('stories_page.catalog.stars_plus')}</option>
                      <option value="2">2 {t('stories_page.catalog.stars_plus')}</option>
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="text-sm theme-text-secondary">
                      <BookMarked size={16} /> {t('story_elements.themes')}
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {availableThemes.map(theme => (
                        <button
                          key={theme}
                          onClick={() => {
                            setFilters({ 
                              ...filters, 
                              theme: filters.theme === theme ? undefined : theme 
                            });
                          }}
                          className={`
                            px-3 py-1 rounded-full text-sm transition-colors
                            ${filters.theme === theme
                              ? 'bg-purple-500/30 text-purple-200'
                              : 'bg-white/5 theme-text/70 hover:bg-white/10'}
                          `}
                        >
                          {t(`options.themes.${theme}`)}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm theme-text-secondary">
                      <Feather size={16} /> {t('story_elements.tones')}
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {availableTones.map(tone => (
                        <button
                          key={tone}
                          onClick={() => {
                            setFilters({ 
                              ...filters, 
                              tone: filters.tone === tone ? undefined : tone 
                            });
                          }}
                          className={`
                            px-3 py-1 rounded-full text-sm transition-colors
                            ${filters.tone === tone
                              ? 'bg-purple-500/30 text-purple-200'
                              : 'bg-white/5 theme-text/70 hover:bg-white/10'}
                          `}
                        >
                          {t(`options.tones.${tone}`)}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm theme-text-secondary">
                      <GraduationCap size={16} /> {t('story_elements.educational_elements')}
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {availableElements.map(element => (
                        <button
                          key={element}
                          onClick={() => {
                            const currentElements = filters.elements || [];
                            const newElements = currentElements.includes(element)
                              ? currentElements.filter(t => t !== element)
                              : [...currentElements, element];
                            setFilters({ ...filters, elements: newElements.length ? newElements : undefined });
                          }}
                          className={`
                            px-3 py-1 rounded-full text-sm transition-colors
                            ${filters.elements?.includes(element)
                              ? 'bg-purple-500/30 text-purple-200'
                              : 'bg-white/5 theme-text/70 hover:bg-white/10'}
                          `}
                        >
                          {t(`options.educationalElements.${element}`)}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm theme-text-secondary">
                      <Heart size={16} /> {t('story_elements.moral_values')}
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {availableValues.map(value => (
                        <button
                          key={value}
                          onClick={() => {
                            const currentValues = filters.values || [];
                            const newValues = currentValues.includes(value)
                              ? currentValues.filter(v => v !== value)
                              : [...currentValues, value];
                            setFilters({ ...filters, values: newValues.length ? newValues : undefined });
                          }}
                          className={`
                            px-3 py-1 rounded-full text-sm transition-colors
                            ${filters.values?.includes(value)
                              ? 'bg-purple-500/30 text-purple-200'
                              : 'bg-white/5 theme-text/70 hover:bg-white/10'}
                          `}
                        >
                          {t(`options.moralValues.${value}`)}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    onClick={() => setFilters({
                      searchQuery: '',
                      sortBy: 'recent',
                      sortOrder: 'desc'
                    })}
                    className="px-4 py-2 bg-white/10 rounded-lg hover:bg-white/20 transition-all"
                  >
                    {t('stories_page.catalog.reset_filters')}
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedAndFilteredStories.map((story: Story) => (
              <StoryCard story={story}></StoryCard>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};