import { useTranslation } from "react-i18next";
import { useAuth } from "../AuthContext/AuthContext";
import { Link } from "react-router-dom";
import { useTheme } from "../ThemeProvider/ThemeProvider";

export const Header: React.FC = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const { isAuthenticated } = useAuth()
  
    return (
        <header className={`fixed top-0 left-0 right-0 z-50 ${theme === 'dark' ? 'bg-black/20' : 'bg-white/60'} backdrop-blur-[2px] border-b ${theme === 'dark' ? 'border-black/5' : 'border-white/5'}`}>
        <div className="max-w-7xl mx-auto px-6 py-4">
          <nav className="flex items-center justify-between">
            <div className="flex items-center gap-8">
                <Link to="/" className="text-2xl font-bold theme-text hover:text-purple-400 transition-colors">
                    {t('home_menu.infinistories')}
                </Link>
                <div className="hidden md:flex items-center gap-6">
                    <a href="/#pricing" className="theme-text-secondary hover:text-purple-400 transition-colors">
                        {t('home_menu.pricing')}
                    </a>
                    <Link to="/#" className="theme-text-secondary hover:text-purple-400 transition-colors">
                        {t('home_menu.blog')}
                    </Link>
                    <Link to="/#" className="theme-text-secondary hover:text-purple-400 transition-colors">
                        {t('home_menu.about')}
                    </Link>
                </div>
            </div>

            {/* Auth buttons */}
            {isAuthenticated ? 
                <div className="flex items-center gap-4">
                    <Link to="/create" className="theme-text-secondary hover:text-purple-400 transition-colors">
                        {t('home_menu.create_story')}
                    </Link>
                    <Link to="/stories" className="theme-text-secondary hover:text-purple-400 transition-colors">
                        {t('home_menu.stories')}
                    </Link>
                </div> :
                <div className="flex items-center gap-4">
                    <Link to="/login" className="theme-text-secondary hover:text-purple-400 transition-colors">
                        {t('home_menu.login')}
                    </Link>
                    <Link
                        to="/signup"
                        className="px-4 py-2 theme-purple-bg theme-border-purple rounded-lg hover:theme-purple-hover transition-all theme-text"
                    >
                        {t('home_menu.signup')}
                    </Link>
                </div>
            }
          </nav>
        </div>
      </header>
    );
  };