import React from 'react';

export const SummarySection: React.FC<{
  step: number;
  currentStep: number;
  title: string;
  children: React.ReactNode;
}> = ({ step, currentStep, title, children }) => (
  <div className={`
    transition-all duration-300
    ${currentStep >= step ? 'opacity-100' : 'opacity-50'}
  `}>
    <div className="flex items-center gap-2 mb-3">
      <div className="h-6 w-6 rounded-full bg-purple-500/20 border border-purple-500/50 flex items-center justify-center text-sm">
        {step}
      </div>
      <h4 className="font-medium theme-text/80">{title}</h4>
    </div>
    <div className="pl-8">
      {children}
    </div>
  </div>
);