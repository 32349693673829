import React from 'react';
import '../styles/HomePage.css';
import { Header } from '../components/Home/Header';
import { MainContent } from '../components/Home/MainContent';
import { Footer } from '../components/Home/Footer';

export const HomePage: React.FC = () => (
  <>
    <Header />

    <MainContent />

    <Footer />
  </>
);

export default HomePage;