import React from 'react';
import { useTranslation } from 'react-i18next';
import { Sun, Moon, ZoomIn, ZoomOut, Gamepad, ScrollText, Clock, Users } from 'lucide-react';
import { ReaderControlsProps } from './types/ReaderControlsTypes';

export const ReaderControls: React.FC<ReaderControlsProps> = ({
  story,
  isDarkTheme,
  isUppercase,
  fontSize,
  onToggleTheme,
  onToggleUppercase,
  onZoom
}) => {
  const { t } = useTranslation();

  return (
    <div className={`
        flex justify-between items-center mb-6 rounded-xl p-4 
        ${isDarkTheme 
          ? 'bg-black/90 border-white/10' 
          : 'bg-white border-gray-200'}
        backdrop-blur-sm border
      `}>
      <div>
        <h1 className={`
          text-2xl font-bold 
          ${isUppercase ? 'uppercase' : ''} 
          ${isDarkTheme ? 'text-white' : 'text-gray-900'}
        `}>
          {story.title}
        </h1>
        <div className={`
          flex items-center gap-4 mt-2 text-sm
          ${isDarkTheme ? 'text-white/60' : 'text-black/60'}
        `}>
          {story.details.settings.isInteractive ? (
            <span className="flex items-center gap-1">
              <Gamepad size={16} />
              {t('interactive_story')}
            </span>
          ) : (
            <span className="flex items-center gap-1">
              <ScrollText size={16} />
              {t('stories.classic_story')}
            </span>
          )}
          {story.details.settings.duration && (
            <span className="flex items-center gap-1">
              <Clock size={16} />
              {story.details.settings.duration} {t('stories_page.card.minutes')}
            </span>
          )}
          {story.details.settings.ageRange && (
            <span className="flex items-center gap-1">
              <Users size={16} />
              {story.details.settings.ageRange} {t('stories_page.card.years')}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2">
        <button
          onClick={onToggleUppercase}
          className={`
            p-2 rounded-lg transition-colors
            ${isDarkTheme ? 'hover:bg-white/5' : 'hover:bg-black/5'}
          `}
        >
          <span className={isDarkTheme ? 'text-white/60' : 'text-black/60'}>
            Aa
          </span>
        </button>
        
        <button
          onClick={onToggleTheme}
          className={`
            p-2 rounded-lg transition-colors
            ${isDarkTheme ? 'hover:bg-white/5' : 'hover:bg-black/5'}
          `}
        >
          {isDarkTheme ? (
            <Sun size={20} className="text-white/60" />
          ) : (
            <Moon size={20} className="text-black/60" />
          )}
        </button>

        <div className="flex items-center gap-2">
          <button
            onClick={() => onZoom(false)}
            className={`
              p-2 rounded-lg transition-colors
              ${isDarkTheme ? 'hover:bg-white/5' : 'hover:bg-black/5'}
            `}
          >
            <ZoomOut size={20} className={isDarkTheme ? 'text-white/60' : 'text-black/60'} />
          </button>
          <span className={isDarkTheme ? 'text-white/60' : 'text-black/60'}>
            {fontSize}
          </span>
          <button
            onClick={() => onZoom(true)}
            className={`
              p-2 rounded-lg transition-colors
              ${isDarkTheme ? 'hover:bg-white/5' : 'hover:bg-black/5'}
            `}
          >
            <ZoomIn size={20} className={isDarkTheme ? 'text-white/60' : 'text-black/60'} />
          </button>
        </div>
      </div>
    </div>
  );
};