import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../ThemeProvider/ThemeProvider';
import { StoryType } from '../../../types/Story';

interface ClassicCardProps {
    storyType: StoryType;
    onSelect: () => void; 
    disabled?: boolean;
    className?: string;
}

export const ClassicCard: React.FC<ClassicCardProps> = ({
    storyType, 
    onSelect,
    disabled = false,
    className = ''
}) => {
    const { t } = useTranslation();
    const { theme } = useTheme();

    const isSelected = storyType === 'classic';

    return (
        <div
            className={`relative group ${isSelected
                ? 'bg-gradient-to-r from-blue-500/40 to-teal-500/40'
                : ''
                } ${className} rounded-xl`}
        >
            <div
                className={`absolute inset-0 rounded-xl blur-sm opacity-70 transition-all group-hover:opacity-100 ${isSelected
                    ? 'bg-gradient-to-r from-blue-500/40 to-teal-500/40'
                    : ''
                    }`}
            />
            <div
                className={`relative backdrop-blur-sm p-8 rounded-xl border transition-all h-full ${isSelected
                    ? 'bg-white/10 border-white/20 hover:border-white/30'
                    : 'bg-white/5 border-white/10 hover:border-white/20'
                    }`}
            >
                <div className="relative h-48 mb-6 rounded-lg overflow-hidden">
                    <div
                        className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-teal-500/10 mix-blend-overlay"
                    />
                    <img
                        src="assets/classic-story.webp"
                        alt={t('home.story_types.classic.title')}
                        className="w-full h-full object-cover"
                    />
                </div>
                <h3 className={`text-2xl font-bold mb-4 bg-clip-text transition-colors ${isSelected
                    ? `text-transparent bg-gradient-to-r ${theme === 'dark'
                        ? 'from-blue-400 to-teal-400'
                        : 'from-blue-600 to-teal-600'
                    }`
                    : 'theme-text/70'
                    }`}
                >
                    {t('home.story_types.classic.title')}
                </h3>
                <p
                    className={`mb-6 transition-colors ${isSelected
                        ? 'theme-text'
                        : 'theme-text/70'
                        }`}
                >
                    {t('home.story_types.classic.description')}
                </p>
                <button
                    disabled={disabled || isSelected}
                    onClick={onSelect}
                    className={`
                        w-full px-6 py-3 rounded-lg border transition-all
                        ${disabled && !isSelected
                            ? 'opacity-50 cursor-not-allowed border-gray-500/30 bg-gray-500/20 hover:bg-gray-500/20'
                            : isSelected
                                ? 'bg-gradient-to-r from-blue-500/40 to-teal-500/40 border-blue-500/50 hover:from-blue-500/50 hover:to-teal-500/50 group-hover:scale-105'
                                : 'bg-gradient-to-r from-blue-500/20 to-teal-500/20 border-blue-500/30 hover:bg-blue-500/30 group-hover:scale-105'
                        }
                        ${disabled && !isSelected ? 'relative overflow-hidden' : ''}
                    `}
                    >
                    {/* Overlay "Coming soon" pour l'état disabled */}
                    {disabled && !isSelected && (
                        <div className="absolute inset-0 flex items-center justify-center backdrop-blur-[1px]">
                            <span className="text-sm text-gray-400 italic">
                                {t('general.coming_soon')}
                            </span>
                        </div>
                    )}
                    
                    {/* Texte du bouton */}
                    <span className={disabled && !isSelected ? 'opacity-0' : ''}>
                        {isSelected ? t('general.selected') : t('general.select')}
                    </span>
                </button>
            </div>
        </div>
    )
};