import React, { useState, useEffect } from 'react';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TextLoading } from '../components/Loading/TextLoading';
import { StoryRow } from '../components/Story/StoryRow';
import { Story } from '../types/Story';
import { AlertCircle, Archive, BookOpen, Plus } from 'lucide-react';
import { getUserStories } from '../api/stories';
import { fromApi } from '../utils/parsedStory';
import { Loading } from '../components/Loading/Loading';
import { useTheme } from '../components/ThemeProvider/ThemeProvider';
import { Layout } from '../components/Layout/Layout';

export const MyStoriesPage: React.FC = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const [loading, setLoading] = useState<boolean>(true);
    const [stories, setStories] = useState<Story[]>([]);
    const [showArchived, setShowArchived] = useState(false);
    
    const userPlan = {
      name: 'Familial',
      monthlyStoryLimit: 10
    };
  
    useEffect(() => {
        const fetchStories = async () => {
            try {
                setLoading(true);
                const stories: any = await getUserStories();
                const parsedStories: Story[] = stories.map((story: any) => {
                    return fromApi(story)
                })
                setStories(parsedStories)
            } catch (error) {
                console.error('Erreur lors du chargement des histoires:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchStories()
    }, []);

    const activeStories = stories.filter(story => !story.details.settings.isArchived);
    const monthlyStoryCount = activeStories.length;
    const storiesRemaining = userPlan.monthlyStoryLimit - monthlyStoryCount;
  
    const filteredStories = stories.filter(story => story.details.settings.isArchived === showArchived);
  
    const handleArchiveToggle = (id: string) => {
      setStories(stories.map(story => 
        story.uuid === id 
          ? { ...story, isArchived: !story.details.settings.isArchived }
          : story
      ));
    };
  
    return (
        <Layout>
            <div className="min-h-screen theme-bg theme-text">
                {/* Background effects */}
                <div className="fixed inset-0 opacity-30">
                    <div className="absolute top-0 right-0 w-[600px] h-[600px] rounded-full bg-purple-500/20 blur-[150px] mix-blend-screen" />
                    <div className="absolute bottom-0 left-0 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[150px] mix-blend-screen" />
                </div>
                <div className="relative z-10 max-w-7xl mx-auto px-6 py-8">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
                        <div>
                            <h1 className={`text-3xl font-bold mb-2 bg-clip-text text-transparent ${
                                theme === 'dark'
                                    ? 'bg-gradient-to-r from-white via-purple-200 to-white'
                                    : 'bg-gradient-to-r from-purple-700 via-purple-600 to-purple-700'
                            }`}>
                                {loading ? 
                                    <TextLoading 
                                        text={t('loading_states.loading_stories')} 
                                        variant='typewriter' 
                                        className={`text-3xl font-bold mb-2 bg-clip-text text-transparent ${
                                            theme === 'dark'
                                                ? 'bg-gradient-to-r from-white via-purple-200 to-white'
                                                : 'bg-gradient-to-r from-purple-700 via-purple-600 to-purple-700'
                                        }`}
                                    /> : 
                                    showArchived ? t('stories.archived_stories') : t('stories.my_stories')
                                }
                            </h1>
                            <p className="theme-text-secondary">
                                {showArchived ? t('stories.manage_archived') : t('stories.manage_stories')}
                            </p>
                        </div>

                        {!loading &&
                            <div className="flex flex-col sm:flex-row items-end sm:items-center gap-4">
                                {!showArchived && (
                                    <div className="text-right">
                                        <div className="text-sm theme-text-secondary mb-1">
                                            {t('stories.stories_this_month')}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="h-2 w-32 bg-white/10 rounded-full overflow-hidden">
                                                <div 
                                                    className="h-full bg-gradient-to-r from-purple-500 to-blue-500 transition-all"
                                                    style={{ width: `${(monthlyStoryCount / userPlan.monthlyStoryLimit) * 100}%` }}
                                                />
                                            </div>
                                            <span className="text-sm font-medium">
                                                {monthlyStoryCount}/{userPlan.monthlyStoryLimit}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <div className="flex gap-2">
                                    <button
                                        onClick={() => setShowArchived(!showArchived)}
                                        className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white/5 hover:bg-white/10 transition-all"
                                    >
                                        {showArchived ? (
                                            <>
                                                <BookOpen size={20} />
                                                {t('stories.view_active')}
                                            </>
                                        ) : (
                                            <>
                                                <Archive size={20} />
                                                {t('stories.view_archived')}
                                            </>
                                        )}
                                    </button>

                                    {!showArchived && (
                                        <Link
                                            to="/create"
                                            className={`
                                                flex items-center gap-2 px-4 py-2 rounded-lg
                                                ${storiesRemaining > 0
                                                    ? 'bg-gradient-to-r from-purple-500 to-blue-500 hover:opacity-90'
                                                    : 'bg-gray-500/50 cursor-not-allowed'}
                                                transition-all
                                            `}
                                        >
                                            <Plus size={20} />
                                            {t('stories.create_story')}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        }
                    </div>

                    {!showArchived && storiesRemaining <= 0 && !loading && (
                        <div className="mb-6 p-4 rounded-lg bg-yellow-500/10 border border-yellow-500/20 text-yellow-400 flex items-center gap-3">
                            <AlertCircle size={20} />
                            <span>
                                {t('stories.story_limit_warning')}
                                <Link to="/pricing" className="underline ml-1">
                                    {t('stories.upgrade_plan')}
                                </Link>
                            </span>
                        </div>
                    )}

                    <div className="space-y-4">
                        {loading ? (
                            <div className="flex justify-center py-12">
                                <Loading
                                    size={200}
                                    variant="gradient"
                                    withText={false}
                                />
                            </div>
                        ) : filteredStories.length > 0 ? (
                            filteredStories.map((story: Story) => (
                                <StoryRow
                                    key={story.uuid}
                                    story={story}
                                    userPlan={userPlan.name}
                                    monthlyStoryCount={monthlyStoryCount}
                                    monthlyStoryLimit={userPlan.monthlyStoryLimit}
                                    onArchiveToggle={handleArchiveToggle}
                                />
                            ))
                        ) : (
                            <div className="text-center py-12 theme-text-secondary">
                                {showArchived
                                    ? t('stories.no_archived_stories')
                                    : t('stories.no_active_stories')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};