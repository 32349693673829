import React from 'react';
import { Sun, Moon, ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { motion } from 'framer-motion';

interface ThemeSwitcherProps {
  variant?: 'button' | 'compact';
  showChevron?: boolean;
  className?: string;
}

export const ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({
  variant = 'button',
  showChevron = false,
  className = ''
}) => {
  const { theme, toggleTheme } = useTheme();
  const { t } = useTranslation();

  // Version compacte pour la navbar ou d'autres utilisations
  if (variant === 'compact') {
    return (
      <button
        onClick={toggleTheme}
        className={`p-2 rounded-lg theme-card theme-border hover:theme-card-hover transition-all ${className}`}
        aria-label={theme === 'dark' ? t('account.switch_to_light') : t('account.switch_to_dark')}
      >
        <motion.div
          initial={false}
          animate={{ rotate: theme === 'dark' ? 0 : 180 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          {theme === 'dark' ? (
            <Sun size={20} className="theme-text-secondary" />
          ) : (
            <Moon size={20} className="theme-text-secondary" />
          )}
        </motion.div>
      </button>
    );
  }

  // Version complète avec texte (par défaut)
  return (
    <button
      onClick={toggleTheme}
      className={`w-full flex items-center justify-between theme-text hover:theme-card-hover transition-all rounded-lg ${className}`}
      aria-label={theme === 'dark' ? t('account.switch_to_light') : t('account.switch_to_dark')}
    >
      <div className="flex items-center gap-3">
        <motion.div
          initial={false}
          animate={{ rotate: theme === 'dark' ? 0 : 180 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          {theme === 'dark' ? (
            <Sun size={20} className="theme-text-secondary" />
          ) : (
            <Moon size={20} className="theme-text-secondary" />
          )}
        </motion.div>
        <div className="text-left">
          <div className="text-sm font-medium theme-text">{t('account.theme')}</div>
          <div className="text-sm theme-text-secondary">
            {theme === 'dark' ? t('account.switch_to_light') : t('account.switch_to_dark')}
          </div>
        </div>
      </div>
      {showChevron && <ChevronRight size={16} className="theme-text-secondary" />}
    </button>
  );
};