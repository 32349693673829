// src/components/MobileProvider/MobileProvider.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSideMenu } from '../SideMenu/SideMenuContext';

interface ResponsiveContextType {
  isMobile: boolean;
  isTablet: boolean;
  containerClassName: string;
}

const ResponsiveContext = createContext<ResponsiveContextType>({
  isMobile: false,
  isTablet: false,
  containerClassName: ''
});

export const useResponsive = () => useContext(ResponsiveContext);

export const ResponsiveProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1024);
  const { isCollapsed } = useSideMenu();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calcule les classes en fonction de l'état du menu
  const containerClassName = isMobile 
  ? 'p-4' 
    : `${isCollapsed ? 'ml-20' : 'ml-72'} p-6 lg:p-8 transition-all duration-300`;

  return (
    <ResponsiveContext.Provider value={{ 
      isMobile, 
      isTablet,
      containerClassName 
    }}>
      {children}
    </ResponsiveContext.Provider>
  );
};