import React, { useEffect, useState } from 'react';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../components/AuthContext/AuthContext';
import {
  User,
  Crown,
  Clock,
  BookOpen,
  Wand2,
  ExternalLink,
  Mail,
  Lock,
  Globe,
  Trash2,
  ChevronRight,
  Flame,
  Sun,
  Moon,
  LanguagesIcon
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { DeleteAccountModal } from '../components/Account/DeleteAccountModal';
import Select from 'react-select';
import { languagesOptions } from '../utils/variables';
import { EmailAccountModal } from '../components/Account/EmailAccountModal';
import { PasswordAccountModal } from '../components/Account/PasswordAccountModal';
import { deleteAccount, getAccountStats, getStripeSessionUrl } from '../api/account';
import { TextLoading } from '../components/Loading/TextLoading';
import { Loading } from '../components/Loading/Loading';
import { useTheme } from '../components/ThemeProvider/ThemeProvider';
import { ThemeSwitcher } from '../components/ThemeProvider/ThemeSwitcher';
import { LanguageSwitcher } from '../components/LanguageSwitcher/LanguageSwitcher';
import { Layout } from '../components/Layout/Layout';

interface StatsProps {
  stories_count: number;
  heroes_count: number;
  stories_readed_count: number;
  stories_readed_duration_total: number;
  stories_adventures_started: number;
  stories_adventures_finished: number;
  stories_duration_total: number;
}

const StatsSection: React.FC<{ stats: StatsProps, loading: boolean }> = ({ stats, loading }) => {
  const { t } = useTranslation()
    
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      <div className="backdrop-blur-sm theme-card border border-white/10 rounded-lg p-4">
        <div className="flex items-center gap-2 theme-text-secondary mb-2">
          <Wand2 size={16} />
          <span className="text-sm">{loading ? <TextLoading text={t('account.computing_in_progress')}></TextLoading> : t('account.stories_created')}</span>
        </div>
        <div className="text-xl font-medium theme-text">{stats.stories_count} ({stats.stories_duration_total} {t('minutes')})</div>
      </div>
      <div className="backdrop-blur-sm theme-card border border-white/10 rounded-lg p-4">
        <div className="flex items-center gap-2 theme-text-secondary mb-2">
          <BookOpen size={16} />
          <span className="text-sm">{loading ? <TextLoading text={t('account.computing_in_progress')}></TextLoading> : t('account.stories_readed')}</span>
        </div>
        <div className="text-xl font-medium theme-text">{stats.stories_readed_count} ({stats.stories_readed_duration_total} {t('minutes')})</div>
      </div>
      <div className="backdrop-blur-sm theme-card border border-white/10 rounded-lg p-4">
        <div className="flex items-center gap-2 theme-text-secondary mb-2">
          <User size={16} />
          <span className="text-sm">{loading ? <TextLoading text={t('account.computing_in_progress')}></TextLoading> : t('account.heroes_created')}</span>
        </div>
        <div className="text-xl font-medium theme-text">{stats.heroes_count}</div>
      </div>
      <div className="backdrop-blur-sm theme-card border border-white/10 rounded-lg p-4">
        <div className="flex items-center gap-2 theme-text-secondary mb-2">
          <Flame size={16} />
          <span className="text-sm">{loading ? <TextLoading text={t('account.computing_in_progress')}></TextLoading> : t('account.adventures_created')}</span>
        </div>
        <div className="text-xl font-medium theme-text">
          {stats.stories_adventures_started ?
            <>`${stats.stories_adventures_finished} / ${stats.stories_adventures_started}` ({((stats.stories_adventures_finished / stats.stories_adventures_started) * 100).toFixed(0)})</>
            : 0
          }
        </div>
      </div>
    </div>
  )
};

const AccountSettings: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { userProfile, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const handleEmailUpdate = async (newEmail: string) => {
    // TODO: Implémentation de la mise à jour de l'email
  };

  const handlePasswordUpdate = async (currentPassword: string, newPassword: string) => {
    // TODO: Implémentation de la mise à jour du mot de passe
  };

  const handleDeleteAccount = async () => {
    try {
      const result = await deleteAccount();
      if (result) {
        setIsAuthenticated(false)
        navigate('/');
      } else {
        console.log('TODO: erreur pendant la suppression');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const selectStyles = {
    control: (base: any) => ({
      ...base,
      background: 'rgba(255, 255, 255, 0.05)',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      '&:hover': {
        borderColor: 'rgba(255, 255, 255, 0.2)'
      }
    }),
    menu: (base: any) => ({
      ...base,
      background: 'rgba(0, 0, 0, 0.9)',
      backdropFilter: 'blur(10px)'
    }),
    option: (base: any, state: any) => ({
      ...base,
      background: state.isFocused ? 'rgba(139, 92, 246, 0.1)' : 'transparent',
      '&:hover': {
        background: 'rgba(139, 92, 246, 0.2)'
      },
      color: 'white'
    }),
    singleValue: (base: any) => ({
      ...base,
      color: 'white'
    })
  };

  return (
    <div className="space-y-6">
      <div className="backdrop-blur-sm theme-card border border-white/10 rounded-lg divide-y divide-white/10">
        {/* Email */}
        <button
          disabled={true}
          onClick={() => setShowEmailModal(true)}
          className="w-full p-4 flex items-center justify-between hover:bg-white/5 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Mail size={20} className="theme-text-secondary" />
            <div className="text-left">
              <div className="text-sm font-medium">{t('account.modify_email')}</div>
              <div className="text-sm theme-text-secondary">{t('account.change_email_connection')}</div>
            </div>
          </div>
          <ChevronRight size={16} className="theme-text-secondary" />
        </button>

        {/* Mot de passe */}
        <button
          disabled={true}
          onClick={() => setShowPasswordModal(true)}
          className="w-full p-4 flex items-center justify-between hover:bg-white/5 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Lock size={20} className="theme-text-secondary" />
            <div className="text-left">
              <div className="text-sm font-medium">{t('account.modify_password')}</div>
              <div className="text-sm theme-text-secondary">{t('account.update_password')}</div>
            </div>
          </div>
          <ChevronRight size={16} className="theme-text-secondary" />
        </button>

        {/* Langue */}
        <div className="p-4">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            {/* Groupe icône et texte à gauche */}
            <div className="flex items-center gap-3">
              <LanguagesIcon size={20} className="theme-text-secondary" />
              <div className="text-left">
                <div className="text-sm font-medium">{t('account.modify_language')}</div>
                <div className="text-sm theme-text-secondary">{t('account.update_language')}</div>
              </div>
            </div>

            {/* LanguageSwitcher à droite */}
            <div className="w-full sm:w-48">
              <LanguageSwitcher />
            </div>
          </div>
        </div>

        {/* Thème */}
        <div className="p-4">
          <ThemeSwitcher showChevron />
        </div>

        {/* Supprimer le compte */}
        <button
          onClick={() => setShowDeleteModal(true)}
          className="w-full p-4 flex items-center gap-3 text-red-400 hover:bg-red-500/5 transition-colors"
        >
          <Trash2 size={20} />
          <div className="text-left">
            <div className="text-sm font-medium">{t('account.delete_account')}</div>
            <div className="text-sm opacity-60">{t('account.delete_account_warning')}</div>
          </div>
        </button>
      </div>
      <>
        {showEmailModal && (
          <EmailAccountModal
            currentEmail={userProfile?.email || ''}
            onClose={() => setShowEmailModal(false)}
            onConfirm={handleEmailUpdate}
          />
        )}

        {showPasswordModal && (
          <PasswordAccountModal
            onClose={() => setShowPasswordModal(false)}
            onConfirm={handlePasswordUpdate}
          />
        )}

        {showDeleteModal && (
          <DeleteAccountModal
            onClose={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteAccount}
          />
        )}
      </>
    </div>
  );
};

export const AccountPage: React.FC = () => {
  const { userProfile, loadingProfile } = useAuth();
  const [stats, setStats] = useState<StatsProps>({
    stories_count: 0,
    heroes_count: 0,
    stories_readed_count: 0,
    stories_readed_duration_total: 0,
    stories_adventures_finished: 0,
    stories_adventures_started: 0,
    stories_duration_total: 0
  });
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const [loadingCustomerPortal, setLoadingCustomerPortal] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoadingStats(true)
        const stats = await getAccountStats();
        setStats(stats)
      } catch (error) {
        console.log('Error fetching account stats', error);
      } finally {
        setLoadingStats(false)
      }
    } 

    fetchStats()
  }, []);

  const handleOpenCustomerPortal = async () => {
    try {
      setLoadingCustomerPortal(true)
      const url = await getStripeSessionUrl();
      if (url) {
        window.location.href = url;
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <Layout>
      <div className="min-h-screen theme-bg theme-text relative overflow-x-hidden">
        <div className="fixed inset-0 opacity-30">
          <div className="absolute top-0 right-0 w-[300px] sm:w-[600px] h-[300px] sm:h-[600px] rounded-full bg-purple-500/20 blur-[100px] sm:blur-[150px] mix-blend-screen" />
          <div className="absolute bottom-0 left-0 w-[300px] sm:w-[600px] h-[300px] sm:h-[600px] rounded-full bg-blue-500/20 blur-[100px] sm:blur-[150px] mix-blend-screen" />
        </div>
        <div className="relative z-10 max-w-7xl mx-auto px-0 sm:px-4">
          <div className="flex flex-col sm:flex-row justify-between items-start gap-4 sm:items-center mb-8">
            {/* Côté gauche : titre et email */}
            <div className="space-y-1">
              <h1 className="text-2xl font-bold theme-text">
                {loadingProfile ? 
                  <TextLoading text={t('account.loading_account')} /> 
                  : t('account.my_account')
                }
              </h1>
              <p className="theme-text-secondary text-sm break-all">{userProfile?.email}</p>
            </div>

            {/* Côté droit : bouton portal */}
            <button
              onClick={handleOpenCustomerPortal}
              className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-purple-500/20 hover:bg-purple-500/30 rounded-lg transition-colors"
            >
              {loadingCustomerPortal ?
                <Loading size={16} withText={false} />
                :
                <Crown size={16} />
              }
              {loadingCustomerPortal ?
                t('general.redirect_loading')
                :
                <>
                  {t('account.manage_subscription')}
                  <ExternalLink size={14} className="ml-1" />
                </>
              }
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="mt-6 space-y-6">
          {/* Progress bar */}
          <div className="backdrop-blur-sm theme-card border border-white/10 rounded-lg p-6">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm theme-text-secondary">{t('account.monthly_stories')}</span>
              <span className="text-sm font-medium">{stats.stories_count}/10</span>
            </div>
            <div className="h-2 bg-white/10 rounded-full overflow-hidden">
              <div
                className="h-full bg-gradient-to-r from-purple-500 to-blue-500"
                style={{ width: `${(stats.stories_count/10)*100}%` }}
              />
            </div>
          </div>

          {/* Stats */}
          <StatsSection stats={stats} loading={loadingStats} />

          {/* Account Settings */}
          {!loadingProfile && <AccountSettings />}
        </div>
      </div>
    </Layout>
  );
};

export default AccountPage;