import { useTranslation } from "react-i18next";
import { useTheme } from "../ThemeProvider/ThemeProvider";
import { LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";
import { ThemeSwitcher } from "../ThemeProvider/ThemeSwitcher";

export const Footer: React.FC = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();
  
    return (
        <footer className="relative overflow-hidden border-t theme-border">
            <div className={`absolute inset-0 ${
            theme === 'dark'
                ? 'bg-[#0A0A0F]/95'
                : 'bg-gray-50/95'
            }`} />
            <div className={`absolute top-0 left-0 w-[500px] h-[500px] rounded-full blur-[100px] ${
            theme === 'dark'
                ? 'bg-purple-500/5'
                : 'bg-purple-200/20'
            }`} />
            <div className={`absolute bottom-0 right-0 w-[500px] h-[500px] rounded-full blur-[100px] ${
            theme === 'dark'
                ? 'bg-blue-500/5'
                : 'bg-blue-200/20'
            }`} />
            <div className="relative max-w-7xl mx-auto px-6 py-16">
                {/* Logo et description */}
                <div className="mb-16">
                    <h3 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent mb-4">
                    {t('general.infinistories')}
                    </h3>
                    <p className="theme-text-secondary max-w-md">
                    {t('home.footer.description')}
                    </p>
                </div>

                {/* Grid principale */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-12">
                    {/* Liens */}
                    <div className="space-y-4">
                    <h4 className="text-sm font-medium uppercase tracking-wider theme-text-secondary mb-6">
                        {t('home_menu.links')}
                    </h4>
                    <ul className="space-y-3">
                        {[
                        { label: t('home_menu.about'), href: '/#' },
                        { label: t('home_menu.contact'), href: 'mailto:contact@infinistories.com' },
                        { label: t('home_menu.privacy'), href: '/#' },
                        { label: t('home_menu.terms'), href: '/#' }
                        ].map((link, index) => (
                        <li key={index}>
                            <a 
                            href={link.href}
                            className="theme-text hover:text-purple-400 transition-colors duration-200 text-sm flex items-center gap-2 group"
                            >
                            <span className="h-px w-4 bg-purple-400/50 group-hover:w-6 transition-all duration-200" />
                            {link.label}
                            </a>
                        </li>
                        ))}
                    </ul>
                    </div>

                    {/* Réseaux sociaux */}
                    <div className="space-y-4">
                    <h4 className="text-sm font-medium uppercase tracking-wider theme-text-secondary mb-6">
                        {t('home_menu.follow_us')}
                    </h4>
                    <div className="flex gap-4">
                        {[
                        { icon: 'twitter', href: '#' },
                        { icon: 'instagram', href: '#' },
                        { icon: 'linkedin', href: '#' }
                        ].map((social) => (
                        <a
                            key={social.icon}
                            href={social.href}
                            className="group relative"
                        >
                            <div className="absolute inset-0 bg-purple-400/20 rounded-full blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                            <div className="relative w-10 h-10 rounded-full border theme-border bg-black/20 backdrop-blur-sm flex items-center justify-center transition-transform duration-200 group-hover:scale-110">
                            <i className={`fab fa-${social.icon} theme-text group-hover:text-purple-400 transition-colors duration-200`} />
                            </div>
                        </a>
                        ))}
                    </div>
                    </div>

                    {/* Sélecteur de langue */}
                    <div className="space-y-4">
                    <h4 className="text-sm font-medium uppercase tracking-wider theme-text-secondary mb-6">
                        {t('home_menu.select_lang')}
                    </h4>
                    <div className="max-w-[200px]">
                        <LanguageSwitcher />
                    </div>
                    </div>

                    {/* Sélecteur de thème */}
                    <div className="space-y-4">
                    <h4 className="text-sm font-medium uppercase tracking-wider theme-text-secondary mb-6">
                        {t('home_menu.select_theme')}
                    </h4>
                    <div className="max-w-[200px]">
                        <ThemeSwitcher variant="compact" />
                    </div>
                    </div>
                </div>

                {/* Copyright */}
                <div className="mt-16 pt-8 border-t theme-border">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                    <p className="text-sm theme-text-secondary">
                        {t('home.footer.copyright')}
                    </p>
                    <div className="text-sm theme-text-secondary flex gap-6">
                        <a href="/#" className="hover:text-purple-400 transition-colors duration-200">
                        {t('home_menu.privacy')}
                        </a>
                        <a href="/#" className="hover:text-purple-400 transition-colors duration-200">
                        {t('home_menu.terms')}
                        </a>
                    </div>
                    </div>
                </div>
            </div>
      </footer>
    );
  };