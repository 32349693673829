import { useState } from "react";
import { Story } from "../../types/Story";
import { Archive, ArchiveRestore, BarChart2, BookOpen, BookOpenText, Clock, Eye, Globe2, Lock, MessageCircle } from "lucide-react";
import { DifficultyBadge } from "./DifficultyBadge";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export const StoryRow: React.FC<{ 
    story: Story,
    userPlan: string,
    monthlyStoryCount: number,
    monthlyStoryLimit: number,
    onArchiveToggle: (id: string) => void 
}> = ({ story, userPlan, monthlyStoryCount, monthlyStoryLimit, onArchiveToggle }) => {
    const [showDetails, setShowDetails] = useState(false);
    const navigate = useNavigate();
  
    return (
      <div className="group relative backdrop-blur-sm theme-card border border-white/10 rounded-lg p-4 hover:border-purple-500/30 transition-all">
        <div className="flex flex-col">
          {/* En-tête fixe avec les actions */}
          <div className="flex items-start justify-between">
            {/* Colonne de gauche avec les infos principales */}
            <div className="flex-grow mr-4">
              <div className="flex items-center gap-3 mb-2">
                <h3 className="text-lg font-semibold theme-text/90">{story.title}</h3>
                {story.details.settings.isPublic ? (
                  <Globe2 size={16} className="text-green-400" />
                ) : (
                  <Lock size={16} className="theme-text/40" />
                )}
                {story.details.settings.isInteractive && (
                  <span className="px-2 py-1 text-xs rounded-full bg-purple-500/10 text-purple-300">
                    Interactive
                  </span>
                )}
                <DifficultyBadge difficulty={story.details.settings.difficulty} />
              </div>
              
              <div className="flex flex-wrap gap-4 text-sm theme-text-secondary">
                {story.details.settings.duration && <span className="flex items-center gap-1">
                  <Clock size={14} />
                  {story.details.settings.duration} min
                </span>}
                <span className="flex items-center gap-1">
                  <BookOpen size={14} />
                  {story.playCount} lectures
                </span>
                {story.details.settings.ageRange && <span className="flex items-center gap-1">
                  <BarChart2 size={14} />
                  {story.details.settings.ageRange} ans
                </span>}
                <span className="flex items-center gap-1">
                  <MessageCircle size={14} />
                  {story.details.settings.language}
                </span>
              </div>
  
              {/* Tags */}
              {story.details?.theme &&
                <div className="flex flex-wrap gap-2 mt-3">
                  <span
                    className="px-2 py-1 text-xs rounded-full bg-purple-500/10 text-purple-300"
                  >
                    {t(`options.themes.${story.details?.theme}`)}
                  </span>
                </div>
              }
            </div>
  
            {/* Colonne de droite fixe avec les actions */}
            <div className="flex items-start gap-4 shrink-0">
                <button 
                  className="p-2 rounded-lg hover:bg-white/5 transition-colors"
                  onClick={() => navigate(`/story/${story.uuid}`)}
                >
                  <BookOpenText size={18} className="theme-text-secondary" />
                </button>
              
              <div className="flex items-center gap-2">
                <button 
                  className="p-2 rounded-lg hover:bg-white/5 transition-colors"
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <Eye size={18} className="theme-text-secondary" />
                </button>
                <button 
                  className="p-2 rounded-lg hover:bg-purple-500/10 transition-colors"
                  onClick={() => onArchiveToggle(story.uuid)}
                  title={story.details.settings.isArchived ? "Désarchiver" : "Archiver"}
                >
                  {story.details.settings.isPublic ? (
                    <ArchiveRestore size={18} className="text-purple-400" />
                  ) : (
                    <Archive size={18} className="theme-text-secondary" />
                  )}
                </button>
              </div>
            </div>
          </div>
  
          {/* Contenu détaillé extensible */}
          {showDetails && (
            <div className="mt-4 space-y-3 border-t border-white/10 pt-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* ... (reste du contenu détaillé identique) ... */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };