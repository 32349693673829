import { useState } from 'react';
import { AlertTriangle, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext/AuthContext';

interface DeleteAccountModalProps {
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ 
  onClose, 
  onConfirm 
}) => {
  const { t } = useTranslation();
  const { userProfile } = useAuth();
  const [confirmText, setConfirmText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (confirmText === userProfile?.email) {
      setLoading(true);
      try {
        await onConfirm();
      } catch (error) {
        console.error('Error deleting account:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop avec flou */}
      <div 
        className="absolute inset-0 theme-bg/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal */}
      <div className="relative w-full max-w-lg max-h-[90vh] overflow-y-auto theme-bg/80 backdrop-blur-md rounded-xl border border-white/10 p-6 m-4">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2 text-red-400">
            <AlertTriangle size={20} />
            <h2 className="text-2xl font-bold">{t('account.delete_account')}</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-lg transition-all"
          >
            <X size={20} />
          </button>
        </div>

        {/* Content */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <p className="theme-text-secondary">
              {t('account.delete_account_warning')}
            </p>
            <div>
              <label className="block text-sm theme-text/80 mb-2">
                {t('account.confirm_by_email', { email: userProfile?.email })}
              </label>
              <input
                type="text"
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-red-500/50 focus:outline-none transition-colors"
                placeholder={t('account.enter_email')}
              />
            </div>
          </div>

          {/* Actions */}
          <div className="flex justify-end gap-4 pt-4 border-t border-white/10">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded-lg hover:bg-white/10 transition-all"
            >
              {t('general.cancel')}
            </button>
            <button
              type="submit"
              disabled={loading || confirmText !== userProfile?.email}
              className="px-4 py-2 bg-red-500/20 text-red-400 hover:bg-red-500/30 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? t('account.deleting') : t('account.delete_permanently')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};