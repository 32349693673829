import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { HeroesPage } from './pages/HeroesPage';
import { LoginPage } from './pages/LoginPage';
import { AuthProvider } from './components/AuthContext/AuthContext';
import './styles/App.css'
import './styles/global.css'
import { AccountPage } from './pages/AccountPage';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { SignupPage } from './pages/SignupPage';
import { StoriesPage } from './pages/StoriesPage';
import { MyStoriesPage } from './pages/MyStoriesPage';
import { CreateStoryPage } from './pages/CreateStoryPage';
import StoryPage from './pages/StoryPage';
import TestPage from './pages/TestPage';
import { ThemeProvider } from './components/ThemeProvider/ThemeProvider';
import { SideMenuProvider } from './components/SideMenu/SideMenuContext';
import { AppLayout } from './components/AppLayout/AppLayout';
import { ResponsiveProvider } from './components/MobileProvider/MobileProvider';

PiwikPro.initialize('f652a00f-1223-4511-a12f-a78698b74573', 'https://histoirepourdormir.piwik.pro');

// Composant pour les pages qui ont besoin du layout avec menu
const LayoutRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  return (
    <AppLayout>
      {element}
    </AppLayout>
  );
};

const App = () => (
  <Router>
    <AuthProvider>
      <ThemeProvider>
        <SideMenuProvider>
          <ResponsiveProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              
              {/* Pages avec le SideMenu */}
              <Route path="/login" element={<LayoutRoute element={<LoginPage />} />} />
              <Route path="/signup" element={<LayoutRoute element={<SignupPage />} />} />
              <Route path="/test" element={<LayoutRoute element={<TestPage />} />} />
              <Route path="/stories" element={<LayoutRoute element={<StoriesPage />} />} />
              <Route path="/story/:uuid" element={<LayoutRoute element={<StoryPage />} />} />
              <Route path="/my-stories" element={<LayoutRoute element={<MyStoriesPage />} />} />
              <Route path="/create" element={<LayoutRoute element={<CreateStoryPage />} />} />
              <Route path="/heroes" element={<LayoutRoute element={<HeroesPage />} />} />
              <Route path="/account" element={<LayoutRoute element={<AccountPage />} />} />
            </Routes>
          </ResponsiveProvider>
        </SideMenuProvider>
      </ThemeProvider>
    </AuthProvider>
  </Router>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>, 
  document.getElementById('root')
);