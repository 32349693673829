import React, { useEffect, useState } from 'react';
import { getUserHeroes } from '../api/heroes';
import { HeroCard } from '../components/HeroCard/HeroCard';
import { TextLoading } from '../components/Loading/TextLoading';
import { StoryDetails } from '../types/StoryDetails';
import { StorySettings } from '../types/StorySettings';
import { Hero } from '../types/Hero';
import { StorySettingsStep } from '../components/Story/StorySettingsStep';
import { StorySummary } from '../components/Story/StorySummary';
import { educationalElementsOptions, moralValuesOptions, themesOptions, tonesOptions } from '../utils/variables';
import { useTranslation } from 'react-i18next';
import { createChapters, createIllustration, createOldStory, createTitleAndSummary } from '../api/stories';
import { useNavigate } from 'react-router-dom';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Info } from 'lucide-react';
import { StoryTypeStep } from '../components/Story/StoryTypeStep';
import { ClassicCard } from '../components/Story/TypeCard/ClassicCard';
import { StoryType } from '../types/Story';
import { InteractiveCard } from '../components/Story/TypeCard/InteractiveCard';
import { Layout } from '../components/Layout/Layout';
import { StorySummaryMobile } from '../components/Story/StorySummaryMobile';
import { useResponsive } from '../components/MobileProvider/MobileProvider';

const lsTempStoryFormDataName = 'story-original-form-data';

export const CreateStoryPage: React.FC = () => {

    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [heroes, setHeroes] = useState<Hero[]>([]);
    const [selectedHeroes, setSelectedHeroes] = useState<Hero[]>([]);
    const [selectedEducationalElements, setSelectedEducationalElements] = useState<string[]>([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [selectedTone, setSelectedTone] = useState<string>('');
    const [selectedTheme, setSelectedTheme] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [storyType, setStoryType] = useState<StoryType>('classic');
    const [additionalContext, setAdditionalContext] = useState('');
    const [createButtonLoading, setCreateButtonLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isQuickMode, setIsQuickMode] = useState(false);

    const [storySettings, setStorySettings] = useState<StorySettings>({
        isInteractive: true,
        duration: 2,
        isPublic: false,
        difficulty: 'medium',
        ageRange: '7-9',
        language: 'fr'
    });

    const [storyData, setStoryData] = useState<StoryDetails>({
        context: '',
        duration: 2,
        heroes: [],
        type: '',
        settings: storySettings,
        illustration_file_url: 'assets/background2.webp'
    });

    const heroUuidFromUrl = new URLSearchParams(location.search).get('hero_uuid');
    const storyTypeFromUrl = new URLSearchParams(location.search).get('type');
    const stepFromUrl = new URLSearchParams(location.search).get('step');

    useEffect(() => {
        if (storyTypeFromUrl && (storyTypeFromUrl === 'classic' || storyTypeFromUrl === 'interactive')) {
            setStoryType(storyTypeFromUrl)
        }
    }, [storyTypeFromUrl]);

    useEffect(() => {
        if (stepFromUrl) {
            setStep(parseInt(stepFromUrl))
        }
    }, [stepFromUrl]);

    useEffect(() => {
        setStorySettings(prevSettings => ({
            ...prevSettings,
            isInteractive: storyType === 'classic' ? false : true
        }))
    }, [storyType]);

    useEffect(() => {
        async function fetchHeroes() {
            setLoading(true);
            try {
                const fetchedHeroes = await getUserHeroes();
                setHeroes(fetchedHeroes);
    
                if (heroUuidFromUrl) {
                    const preselectedHero = fetchedHeroes.find(
                        (hero: Hero) => hero.uuid === heroUuidFromUrl
                    );
                    if (preselectedHero) {
                        const heroWithMain = { ...preselectedHero, isMainCharacter: true };
                        setSelectedHeroes([heroWithMain]);
                        setStoryData(prevData => ({
                            ...prevData,
                            heroes: [heroWithMain]
                        }));
                    }
                }
            } catch (error) {
                console.error('Error fetching heroes:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchHeroes();

        // Restore saved data from localStorage
        const savedData = JSON.parse(localStorage.getItem(lsTempStoryFormDataName) || '{}');
        if (savedData.step) {
            setStep(savedData.step);
            setSelectedHeroes(savedData.selectedHeroes || []);
            setSelectedEducationalElements(savedData.selectedEducationalElements || []);
            setSelectedValues(savedData.selectedValues || []);
            setSelectedTone(savedData.selectedTone || '');
            setSelectedTheme(savedData.selectedTheme || '');
            setStoryType(savedData.storyType || 'classic');
            setStorySettings(savedData.storySettings || {
                isInteractive: true,
                duration: 2,
                isPublic: false,
                difficulty: 'medium',
                ageRange: '7-9',
                language: 'fr'
            });
            setAdditionalContext(savedData.additionalContext || '');
        } else {
            // Reset localStorage data if the user is not on the first step
            localStorage.removeItem(lsTempStoryFormDataName);
        }
    }, [heroUuidFromUrl]);

    useEffect(() => {
        const currentState = {
            selectedHeroes,
            selectedEducationalElements,
            selectedValues,
            selectedTone,
            selectedTheme,
            storyType,
            storySettings,
            additionalContext,
            step
        };
        localStorage.setItem(lsTempStoryFormDataName, JSON.stringify(currentState));
    }, [selectedHeroes, selectedEducationalElements, selectedValues, selectedTone, selectedTheme, storyType, storySettings, additionalContext, step]);

    const handleHeroSelection = (hero: Hero) => {
        setSelectedHeroes(prev => {
            const isAlreadySelected = prev.some(h => h.uuid === hero.uuid);
            if (isAlreadySelected) {
                return prev.filter(h => h.uuid !== hero.uuid);
            } else {
                const isFirstHero = prev.length === 0;
                const heroToAdd = { ...hero, isMainCharacter: isFirstHero };
                return [...prev, heroToAdd];
            }
        });
    };

    const handleCreateStory = async () => {
        try {
            setCreateButtonLoading(true);
            
            const storyData = {
                type: storyType,
                tone: selectedTone,
                theme: selectedTheme,
                prompt: additionalContext,
                educational_elements: selectedEducationalElements,
                moral_values: selectedValues,
                heroes: selectedHeroes,
                settings: storySettings,
                language: storySettings.language,
                duration: storySettings.duration,
            }
            const createStoryResponse = await createOldStory(storyData);
            const storyUUID = createStoryResponse.story_uuid;

            localStorage.removeItem(lsTempStoryFormDataName);
            navigate(`/story/${storyUUID}`);

            if (!storyData.settings.isInteractive) {
                await createChapters(storyUUID, storyData);
                await Promise.all([
                    createTitleAndSummary(storyUUID),
                    createIllustration(storyUUID),
                ]);
            }
            
        } catch (error) {
            setErrorMessage('error');
        } finally {
            setCreateButtonLoading(false);
        }
    };

    const handleQuickCreate = async () => {
        try {
            setCreateButtonLoading(true);
            
            // Sélection aléatoire des valeurs éducatives et morales
            const randomEducationalElements = educationalElementsOptions(t)
                .sort(() => 0.5 - Math.random())
                .slice(0, 2)
                .map(e => e.value);

            const randomValues = moralValuesOptions(t)
                .sort(() => 0.5 - Math.random())
                .slice(0, 2)
                .map(v => v.value);

            const quickStoryData = {
                type: storyType,
                tone: selectedTone || tonesOptions(t)[Math.floor(Math.random() * tonesOptions(t).length)].value,
                theme: selectedTheme || themesOptions(t)[Math.floor(Math.random() * themesOptions(t).length)].value,
                prompt: '',
                educational_elements: randomEducationalElements,
                moral_values: randomValues,
                heroes: selectedHeroes,
                settings: {
                    ...storySettings,
                    duration: 5,
                    difficulty: 'medium',
                    ageRange: '7-9',
                    isPublic: false
                },
                language: storySettings.language,
            }

            const createStoryResponse = await createOldStory(quickStoryData);
            const storyUUID = createStoryResponse.story_uuid;

            localStorage.removeItem(lsTempStoryFormDataName);
            navigate(`/story/${storyUUID}`);

            if (!quickStoryData.settings.isInteractive) {
                await createChapters(storyUUID, quickStoryData);
                await Promise.all([
                    createTitleAndSummary(storyUUID),
                    createIllustration(storyUUID),
                ]);
            }
            
        } catch (error) {
            setErrorMessage('error');
        } finally {
            setCreateButtonLoading(false);
        }
    };

    const handleSetMainHero = (hero: Hero) => {
        setSelectedHeroes(prev => 
            prev.map(h => ({
                ...h,
                isMainCharacter: h.uuid === hero.uuid
            }))
        );
    };

    return (
        <Layout>
            <div className="min-h-screen theme-bg theme-text relative overflow-x-hidden">
                <div className="flex-1 min-h-screen theme-bg theme-text">
                    <div className="fixed inset-0 opacity-30">
                        <div className="absolute top-0 right-0 w-[600px] h-[600px] rounded-full bg-purple-500/20 blur-[150px] mix-blend-screen" />
                        <div className="absolute bottom-0 left-0 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[150px] mix-blend-screen" />
                    </div>

                    <div className="relative flex min-h-screen">
                        <div className="flex-1 lg:mr-80">
                            <div className="max-w-4xl mx-auto px-4 py-8">
                                <div className="flex justify-between items-center mb-12">
                                    <h1 className="text-4xl font-bold">{t('create_story.title')}</h1>
                                    <div className="flex items-center gap-4">
                                        <button
                                            onClick={handleQuickCreate}
                                            disabled={!storyType || selectedHeroes.length === 0 || createButtonLoading}
                                            className="px-4 py-2 bg-gradient-to-r from-purple-500/80 to-blue-500/80 rounded-lg hover:from-purple-500/90 hover:to-blue-500/90 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            {createButtonLoading ? 
                                                <TextLoading text={t('general.saving')} /> : 
                                                t('create_story.create_quickly')
                                            }
                                        </button>
                                        <div className="theme-text-secondary">
                                            {t('create_story.step')} {step}/4
                                        </div>
                                    </div>
                                </div>

                                <div className="space-y-8 backdrop-blur-sm theme-card p-8 rounded-xl border border-white/10">
                                    {step === 1 && (
                                        <section className="relative">
                                            <div className="max-w-7xl mx-auto px-6">
                                                <div className="grid lg:grid-cols-2 gap-8">
                                                    {/* Classic Story Card */}
                                                    <ClassicCard 
                                                        storyType={storyType} 
                                                        onSelect={() => setStoryType('classic')} 
                                                    />

                                                    {/* Interactive Story Card */}
                                                    <InteractiveCard
                                                        storyType={storyType} 
                                                        onSelect={() => setStoryType('interactive')} 
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </section>
                                    )}

                                    {step === 2 && (
                                        <div>
                                            <h2 className="text-2xl font-semibold mb-6">
                                                {loading ? <TextLoading text={t('create_story.loading_heroes')}></TextLoading> : t('create_story.choose_heroes')}
                                            </h2>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {heroes?.map((hero: Hero) => (
                                                    <HeroCard 
                                                        key={hero.uuid}
                                                        hero={hero}
                                                        handleClick={() => handleHeroSelection(hero)}
                                                        handleDoubleClick={() => {
                                                            if (selectedHeroes.some(h => h.uuid === hero.uuid)) {
                                                                handleSetMainHero(hero);
                                                            }
                                                        }}
                                                        isSelected={selectedHeroes.some(h => h.uuid === hero.uuid)}
                                                        isMainCharacter={selectedHeroes.some(h => h.uuid === hero.uuid && h.isMainCharacter)}
                                                        withStartButton={false}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {step === 3 && (
                                        <div>
                                            <h2 className="text-2xl font-semibold mb-6">
                                                {t('create_story.educational_values_title')}
                                            </h2>
                                            <div className="space-y-6">
                                                <div>
                                                    <label className="block text-lg mb-2">
                                                        {t('create_story.educational_themes')}
                                                    </label>
                                                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                                        {educationalElementsOptions(t).map((theme) => (
                                                            <Tooltip.Provider key={theme.value}>
                                                            <Tooltip.Root>
                                                                <div className="relative">
                                                                <button
                                                                    onClick={() => setSelectedEducationalElements(prev => 
                                                                    prev.includes(theme.value) 
                                                                        ? prev.filter(t => t !== theme.value)
                                                                        : [...prev, theme.value]
                                                                    )}
                                                                    className={`
                                                                    p-2 pr-8 rounded border transition-all w-full text-left
                                                                    ${selectedEducationalElements.includes(theme.value)
                                                                        ? 'bg-purple-500/20 border-purple-500/50 theme-text'
                                                                        : 'bg-white/5 hover:bg-white/10 border-white/10'}
                                                                    `}
                                                                >
                                                                    {theme.label}
                                                                </button>
                                                                <Tooltip.Trigger asChild>
                                                                    <button
                                                                    type="button"
                                                                    className="absolute right-2 top-1/2 -translate-y-1/2 p-1 hover:bg-white/10 rounded-full"
                                                                    >
                                                                    <Info size={14} className="theme-text-secondary" />
                                                                    </button>
                                                                </Tooltip.Trigger>
                                                                </div>
                                                                <Tooltip.Portal>
                                                                <Tooltip.Content
                                                                    className="theme-bg/90 backdrop-blur-sm theme-text p-3 rounded-lg text-sm max-w-xs border border-white/10"
                                                                    side="right"
                                                                    sideOffset={5}
                                                                >
                                                                    {t(`options.educationalElements.descriptions.${theme.value}`)}
                                                                    <Tooltip.Arrow className="fill-black/90" />
                                                                </Tooltip.Content>
                                                                </Tooltip.Portal>
                                                            </Tooltip.Root>
                                                            </Tooltip.Provider>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="block text-lg mb-2">
                                                        {t('create_story.moral_values')}
                                                    </label>
                                                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                                        {moralValuesOptions(t).map((moral) => (
                                                            <Tooltip.Provider key={moral.value}>
                                                                <Tooltip.Root>
                                                                    <div className="relative">
                                                                        <button
                                                                            onClick={() => setSelectedValues(prev => 
                                                                                prev.includes(moral.value) 
                                                                                    ? prev.filter(v => v !== moral.value)
                                                                                    : [...prev, moral.value]
                                                                            )}
                                                                            className={`
                                                                                p-2 pr-8 rounded border transition-all w-full text-left
                                                                                ${selectedValues.includes(moral.value)
                                                                                    ? 'bg-purple-500/20 border-purple-500/50 theme-text'
                                                                                    : 'bg-white/5 hover:bg-white/10 border-white/10'}
                                                                            `}
                                                                        >
                                                                            {moral.label}
                                                                        </button>
                                                                        <Tooltip.Trigger asChild>
                                                                            <button
                                                                                type="button"
                                                                                className="absolute right-2 top-1/2 -translate-y-1/2 p-1 hover:bg-white/10 rounded-full"
                                                                            >
                                                                                <Info size={14} className="theme-text-secondary" />
                                                                            </button>
                                                                        </Tooltip.Trigger>
                                                                    </div>
                                                                    <Tooltip.Portal>
                                                                        <Tooltip.Content
                                                                            className="theme-bg/90 backdrop-blur-sm theme-text p-3 rounded-lg text-sm max-w-xs border border-white/10"
                                                                            side="right"
                                                                            sideOffset={5}
                                                                        >
                                                                            {t(`options.moralValues.descriptions.${moral.value}`)}
                                                                            <Tooltip.Arrow className="fill-black/90" />
                                                                        </Tooltip.Content>
                                                                    </Tooltip.Portal>
                                                                </Tooltip.Root>
                                                            </Tooltip.Provider>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {step === 4 && (
                                        <>
                                            <StorySettingsStep
                                                settings={storySettings}
                                                onSettingsChange={setStorySettings}
                                                isQuickMode={isQuickMode}
                                            />
                                            <StoryTypeStep
                                                selectedTheme={selectedTheme}
                                                selectedTone={selectedTone}
                                                onToneChange={setSelectedTone}
                                                onThemeChange={setSelectedTheme}
                                                additionalContext={additionalContext}
                                                onAdditionalContextChange={setAdditionalContext}
                                                isQuickMode={isQuickMode}
                                            />
                                        </>
                                    )}

                                    <div className="flex justify-between mt-8">
                                        {step > 1 && (
                                            <button
                                                onClick={() => {
                                                    const savedData = JSON.parse(localStorage.getItem(lsTempStoryFormDataName) || '{}');
                                                    if (savedData.step && savedData.step === 1) {
                                                        setStep(1);
                                                        setSelectedHeroes(savedData.selectedHeroes || []);
                                                        setSelectedEducationalElements(savedData.selectedEducationalElements || []);
                                                        setSelectedValues(savedData.selectedValues || []);
                                                        setSelectedTone(savedData.selectedTone || '');
                                                        setSelectedTheme(savedData.selectedTheme || '');
                                                        setStoryType(savedData.storyType || 'classic');
                                                        setStorySettings(savedData.storySettings || {
                                                            isInteractive: true,
                                                            duration: 2,
                                                            isPublic: false,
                                                            difficulty: 'medium',
                                                            ageRange: '7-9',
                                                            language: 'fr'
                                                        });
                                                        setAdditionalContext(savedData.additionalContext || '');
                                                    } else {
                                                        setStep(step - 1);
                                                    }
                                                }}
                                                className="px-6 py-3 bg-white/5 rounded-lg hover:bg-white/10 transition-all"
                                            >
                                                {t('create_story.back')}
                                            </button>
                                        )}
                                        
                                        {step < 4 ? (
                                            <button
                                                onClick={() => setStep(step + 1)}
                                                disabled={
                                                    (step === 2 && selectedHeroes.length === 0) ||
                                                    (step === 3 && (selectedEducationalElements.length === 0 || selectedValues.length === 0))
                                                }
                                                className="px-6 py-3 bg-white/10 rounded-lg hover:bg-white/20 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                                            >
                                                {t('create_story.continue')}
                                            </button>
                                        ) : (
                                            <button
                                                onClick={handleCreateStory}
                                                disabled={!storyType || createButtonLoading}
                                                className="px-6 py-3 bg-gradient-to-r from-purple-500/80 to-blue-500/80 rounded-lg hover:from-purple-500/90 hover:to-blue-500/90 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                                            >
                                                    {createButtonLoading ? <TextLoading text={t('general.saving')}></TextLoading> : t('create_story.create')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!isMobile && (
                        <StorySummary
                            selectedHeroes={selectedHeroes}
                            selectedEducationalElements={selectedEducationalElements}
                            selectedValues={selectedValues}
                            selectedTone={selectedTone}
                            selectedTheme={selectedTheme}
                            storyType={storyType}
                            currentStep={step}
                            isInteractive={storySettings.isInteractive}
                            settings={storySettings}
                            className="hidden lg:block"
                        />
                    )}

                    {isMobile && (
                        <StorySummaryMobile
                            selectedHeroes={selectedHeroes}
                            selectedEducationalElements={selectedEducationalElements}
                            selectedValues={selectedValues}
                            selectedTone={selectedTone}
                            selectedTheme={selectedTheme}
                            storyType={storyType}
                            currentStep={step}
                            isInteractive={storySettings.isInteractive}
                            settings={storySettings}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
};