import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  PenTool, 
  Users, 
  User, 
  LogOut, 
  Menu, 
  X, 
  BookOpen,
  Library,
  ChevronLeft,
  ChevronRight,
  Infinity
} from 'lucide-react';
import { logout } from '../../api/auth-users';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { useSideMenu } from './SideMenuContext';

interface SideMenuProps {
  children?: React.ReactNode;
}

export const SideMenu: React.FC<SideMenuProps> = ({ children }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { isCollapsed, setIsCollapsed } = useSideMenu();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    localStorage.setItem('menu_is_collapsed', isCollapsed ? 'true': 'false');
  }, [isCollapsed]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = async () => {
    setIsLoading(true);
    await logout();
    setIsLoading(false);
    window.location.href = '/login';
  };

  const getActiveStyles = (isActive: boolean) => {
    if (isActive) {
      return theme === 'dark' 
        ? 'bg-white/10 text-white' 
        : 'bg-black/5 text-black';
    }
    return theme === 'dark'
      ? 'text-white/70 hover:bg-white/5 hover:text-white'
      : 'text-black/70 hover:bg-black/5 hover:text-black';
  };

  const menuItems = [
    { path: '/stories', icon: <Library size={20} />, label: 'menu.menu_home' },
    ...(isAuthenticated ? [
      { path: '/create', icon: <PenTool size={20} />, label: 'menu.menu_create_adventure' },
      { path: '/my-stories', icon: <BookOpen size={20} />, label: 'menu.menu_my_stories' },
      { path: '/heroes', icon: <Users size={20} />, label: 'menu.menu_my_heroes' },
      { path: '/account', icon: <User size={20} />, label: 'menu.menu_my_account' }
    ] : [
      { path: '/login?prev=create', icon: <PenTool size={20} />, label: 'menu.menu_create_adventure' },
      { path: '/login?prev=heroes', icon: <Users size={20} />, label: 'menu.menu_my_heroes' }
    ])
  ];

  return (
    <>
      {/* Menu latéral */}
      <aside 
        className={`
          fixed inset-y-0 left-0
          ${isCollapsed ? 'w-20' : 'w-72'}
          ${theme === 'dark' 
            ? 'bg-black/40 border-white/10' 
            : 'bg-white/40 border-black/10'} 
          backdrop-blur-md
          border-r 
          transition-all duration-300 ease-in-out z-40
          ${isMobile ? (isOpen ? 'translate-x-0' : '-translate-x-full') : 'translate-x-0'}
        `}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className={`relative p-6 border-b flex items-center justify-between ${
            theme === 'dark' ? 'border-white/10' : 'border-black/10'
          }`}>
            {!isCollapsed ? (
              <h1 className="text-2xl font-bold tracking-wider">
                <Link 
                  to="/" 
                  className={`text-2xl font-bold transition-colors ${
                    theme === 'dark'
                      ? 'text-white hover:text-purple-400'
                      : 'text-black hover:text-purple-600'
                  }`}
                >
                  {t('general.infinistories')}
                </Link>
              </h1>
            ) : (
              <h1 className="text-2xl font-bold tracking-wider">
                <Link 
                  to="/" 
                  className={`text-2xl font-bold transition-colors ${
                    theme === 'dark'
                      ? 'text-white hover:text-purple-400'
                      : 'text-black hover:text-purple-600'
                  }`}
                >
                  <Infinity/>
                </Link>
              </h1>
            )}
            {/* Bouton de collapse */}
            {!isMobile &&
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className={`
                  absolute -right-4 top-1/2 -translate-y-1/2
                  w-8 h-8 rounded-full flex items-center justify-center
                  ${theme === 'dark' 
                    ? 'bg-slate-800 text-white/70 hover:text-white border-slate-700' 
                    : 'bg-white text-black/70 hover:text-black border-slate-200'
                  }
                  border
                  shadow-sm backdrop-blur-sm
                  transition-all duration-200
                  hover:bg-purple-500/20 hover:border-purple-500/50
                  hidden md:flex
                `}
              >
                {isCollapsed ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
              </button>
            }
          </div>

          {/* Navigation */}
          <nav className="flex-1 p-4 overflow-y-auto custom-scrollbar">
            <ul className="space-y-2">
              {menuItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className={`
                      flex items-center gap-3 p-3 rounded-lg
                      transition-all duration-200 relative group
                      ${getActiveStyles(location.pathname === item.path)}
                    `}
                  >
                    {item.icon}
                    {!isCollapsed && <span>{t(item.label)}</span>}
                    {/* Tooltip pour le mode collapsed */}
                    {isCollapsed && (
                      <div className="
                        absolute left-full ml-2 px-2 py-1 rounded-md
                        bg-black/80 text-white text-sm whitespace-nowrap
                        opacity-0 group-hover:opacity-100
                        pointer-events-none transition-opacity duration-200
                        z-50
                      ">
                        {t(item.label)}
                      </div>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Footer */}
          <div className={`p-4 border-t ${
            theme === 'dark' ? 'border-white/10' : 'border-black/10'
          }`}>
            {isAuthenticated ? (
              <button
                onClick={handleLogout}
                disabled={isLoading}
                className={`
                  w-full flex items-center gap-3 p-3 rounded-lg relative group
                  transition-all duration-200
                  ${theme === 'dark'
                    ? 'text-white/70 hover:bg-white/5 hover:text-white'
                    : 'text-black/70 hover:bg-black/5 hover:text-black'}
                `}
              >
                <LogOut size={20} />
                {!isCollapsed && (
                  <span>{isLoading ? t('auth.logging_out_inprogress') : t('auth.logout')}</span>
                )}
                {/* Tooltip pour le mode collapsed */}
                {isCollapsed && (
                  <div className="
                    absolute left-full ml-2 px-2 py-1 rounded-md
                    bg-black/80 text-white text-sm whitespace-nowrap
                    opacity-0 group-hover:opacity-100
                    pointer-events-none transition-opacity duration-200
                    z-50
                  ">
                    {isLoading ? t('auth.logging_out_inprogress') : t('auth.logout')}
                  </div>
                )}
              </button>
            ) : (
              <div className="space-y-2">
                <Link
                  to="/login"
                  className={`
                    w-full flex items-center gap-3 p-3 rounded-lg relative group
                    transition-all duration-200
                    ${theme === 'dark'
                      ? 'text-white/70 hover:bg-white/5 hover:text-white'
                      : 'text-black/70 hover:bg-black/5 hover:text-black'}
                  `}
                >
                  <User size={20} />
                  {!isCollapsed && <span>{t('auth.login')}</span>}
                  {/* Tooltip pour le mode collapsed */}
                  {isCollapsed && (
                    <div className="
                      absolute left-full ml-2 px-2 py-1 rounded-md
                      bg-black/80 text-white text-sm whitespace-nowrap
                      opacity-0 group-hover:opacity-100
                      pointer-events-none transition-opacity duration-200
                      z-50
                    ">
                      {t('auth.login')}
                    </div>
                  )}
                </Link>
              </div>
            )}
          </div>
        </div>
      </aside>

      {/* Bouton toggle mobile */}
      {isMobile && (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`
            fixed top-4 left-4 z-50
            p-2 rounded-lg
            ${theme === 'dark'
              ? 'bg-black/40 border-white/10 text-white/70 hover:text-white'
              : 'bg-white/40 border-black/10 text-black/70 hover:text-black'}
            backdrop-blur-md
            border
            transition-all duration-200
          `}
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      )}

      {/* Overlay mobile */}
      {isMobile && isOpen && (
        <div
          className={`fixed inset-0 backdrop-blur-sm z-30 ${
            theme === 'dark' ? 'bg-black/50' : 'bg-white/50'
          }`}
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};