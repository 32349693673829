import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from "lucide-react";
import './StoryReader.css';
import { Story } from "../../../types/Story";
import { useAuth } from "../../AuthContext/AuthContext";
import { createIllustration, readStory } from "../../../api/stories";
import { ReaderControls } from "./ReaderControls";
import { ReaderContent } from "./ReaderContent";
import { ReaderSidebar } from "./ReaderSidebar";

interface StoryReaderProps {
  currentStory: Story;
}

const StoryReader: React.FC<StoryReaderProps> = ({ currentStory }) => {
  // States from localStorage
  const lsStoryIsFontSize = localStorage.getItem('story_is_font_size') || '16';
  const lsStoryIsUppercase = localStorage.getItem('story_is_uppercase') === 'true';
  const lsStoryIsDarkTheme = localStorage.getItem('story_is_dark_theme') === 'true';

  // State management
  const [story, setStory] = useState<Story>(currentStory);
  const [fontSize, setFontSize] = useState(parseInt(lsStoryIsFontSize));
  const [isUppercase, setIsUppercase] = useState(lsStoryIsUppercase);
  const [isDarkTheme, setIsDarkTheme] = useState(lsStoryIsDarkTheme);
  const [showMobileInfo, setShowMobileInfo] = useState(false);
  const [loadingIllustration, setLoadingIllustration] = useState(false);

  const { isAuthenticated, userProfile } = useAuth();
  const { t } = useTranslation();

  // Effects
  useEffect(() => {
    setStory(currentStory);
    if (currentStory) {
      setTimeout(async () => {
        await readStory(currentStory.uuid);
      }, 20000);
    }
  }, [currentStory]);

  useEffect(() => {
    localStorage.setItem('story_is_dark_theme', isDarkTheme.toString());
    localStorage.setItem('story_is_uppercase', isUppercase.toString());
    localStorage.setItem('story_is_font_size', fontSize.toString());
  }, [isDarkTheme, isUppercase, fontSize]);

  // Handlers
  const handleZoom = (increment: boolean) => {
    setFontSize(prev => {
      const newSize = increment ? prev + 2 : prev - 2;
      return Math.min(Math.max(newSize, 12), 40);
    });
  };

  const handleToggleUppercase = () => setIsUppercase(prev => !prev);
  const handleToggleDarkTheme = () => setIsDarkTheme(prev => !prev);

  const handleGenerateIllustration = async (story: Story) => {
    try {
      setLoadingIllustration(true);
      const illustration = await createIllustration(story.uuid);
      setStory(prevStory => ({
        ...prevStory,
        details: {
          ...prevStory.details,
          illustration_file_url: illustration.data
        }
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIllustration(false);
    }
  };

  const toggleMobileInfo = () => {
    setShowMobileInfo(prev => !prev);
  };

  return (
    <div className={`relative min-h-screen ${isDarkTheme ? 'dark' : ''}`}>
      {/* Background gradients */}
      <div className="fixed inset-0 opacity-30">
        <div className="absolute top-0 right-0 w-[600px] h-[600px] rounded-full bg-purple-500/20 blur-[150px] mix-blend-screen" />
        <div className="absolute bottom-0 left-0 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[150px] mix-blend-screen" />
      </div>

      <div className="relative flex flex-col lg:flex-row min-h-screen">
        {/* Main content area */}
        <div className="flex-1 p-4 md:p-6">
          <ReaderControls
            story={story}
            isDarkTheme={isDarkTheme}
            isUppercase={isUppercase}
            fontSize={fontSize}
            onToggleTheme={handleToggleDarkTheme}
            onToggleUppercase={handleToggleUppercase}
            onZoom={handleZoom}
          />

          <ReaderContent
            story={story}
            isDarkTheme={isDarkTheme}
            isUppercase={isUppercase}
            fontSize={fontSize}
          />
        </div>

        {/* Desktop sidebar */}
        <ReaderSidebar
          story={story}
          isDarkTheme={isDarkTheme}
          isAuthenticated={isAuthenticated}
          userProfile={userProfile}
          onGenerateIllustration={handleGenerateIllustration}
          loadingIllustration={loadingIllustration}
        />

        {/* Mobile sidebar button et panel */}
        <div className="lg:hidden">
          {/* Panel déplié */}
          <div 
            className={`
              fixed inset-0 z-40
              transition-transform duration-300 ease-in-out
              ${showMobileInfo ? 'translate-y-0' : 'translate-y-full'}
              ${isDarkTheme 
                ? 'bg-black border-t border-white/10' 
                : 'bg-white border-t border-black/10'}
              backdrop-blur-lg
              overflow-hidden
            `}
          >
            {/* Drag handle */}
            <button 
              onClick={toggleMobileInfo}
              className={`
                sticky top-0 left-0 right-0 px-4 py-3 
                flex flex-col items-center gap-1 w-full
                border-b ${isDarkTheme ? 'border-white/10' : 'border-black/10'}
              `}
            >
              <div className="w-12 h-1 rounded-full bg-gray-400/50"></div>
              <span className={`text-sm font-medium ${isDarkTheme ? 'text-white' : 'text-black'}`}>
                {showMobileInfo ? t('hide_informations') : t('show_informations')}
              </span>
            </button>

            {/* Contenu scrollable */}
            <div className="overflow-y-auto h-full p-4">
              <ReaderSidebar
                story={story}
                isDarkTheme={isDarkTheme}
                isAuthenticated={isAuthenticated}
                userProfile={userProfile}
                onGenerateIllustration={handleGenerateIllustration}
                loadingIllustration={loadingIllustration}
              />
            </div>
          </div>

          {/* Overlay */}
          {showMobileInfo && (
            <div
              className="fixed inset-0 z-30 bg-black/40"
              onClick={toggleMobileInfo}
            />
          )}

          {/* Bouton flottant */}
          <button
            onClick={toggleMobileInfo}
            className={`
              fixed bottom-4 left-4 right-4 z-50
              py-3 px-6 rounded-lg
              shadow-lg
              flex items-center justify-between
              ${isDarkTheme 
                ? 'bg-black border-white/10 text-white' 
                : 'bg-white border-black/10 text-black'}
              border
            `}
          >
            <span className="font-medium">Informations de l'histoire</span>
            <ChevronUp 
              size={20} 
              className={`transform transition-transform duration-200 ${showMobileInfo ? 'rotate-180' : ''}`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoryReader;