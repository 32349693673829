import { useTranslation } from "react-i18next";
import { Hero } from "../../types/Hero";
import { useState } from "react";
import { activitiesOptions, colorsOptions, roleOptions } from "../../utils/variables";
import Select from 'react-select';
import { Trash2, Wand2, X } from "lucide-react";
import { Option } from "../../types/Option";

interface HeroFormModalProps {
  hero: Hero | null;
  onClose: () => void;
  onSave: (hero: Hero) => Promise<void>;
  onGenerateAvatar?: (heroId: string) => Promise<string>;
}

export const HeroFormModal: React.FC<HeroFormModalProps> = ({ 
  hero, 
  onClose, 
  onSave,
  onGenerateAvatar 
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Hero>({
    uuid: hero?.uuid || '',
    name: hero?.name || '',
    age: hero?.age || undefined,
    favorite_colors: hero?.favorite_colors || [],
    favorite_activities: hero?.favorite_activities || [],
    role: hero?.role || '',
    avatar_file_url: hero?.avatar_file_url || ''
  });
  const [isCustomRole, setIsCustomRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [currentHero, setCurrentHero] = useState(hero);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSave(formData);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateAvatar = async () => {
    if (!onGenerateAvatar || isGenerating || !formData.uuid) return;
    setIsGenerating(true);
    try {
      const newAvatarUrl = await onGenerateAvatar(formData.uuid);
      setFormData(prev => ({
        ...prev,
        avatar_file_url: newAvatarUrl
      }));
    } catch (error) {
      console.error('Error generating avatar:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRemoveAvatar = () => {
    setFormData(prev => ({
      ...prev,
      avatar_file_url: ''
    }));
  };

  // Style personnalisé pour react-select
  const selectStyles = {
    control: (base: any) => ({
      ...base,
      background: 'rgba(255, 255, 255, 0.05)',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      '&:hover': {
        borderColor: 'rgba(255, 255, 255, 0.2)'
      }
    }),
    menu: (base: any) => ({
      ...base,
      background: 'rgba(0, 0, 0, 0.9)',
      backdropFilter: 'blur(10px)'
    }),
    option: (base: any, state: any) => ({
      ...base,
      background: state.isFocused ? 'rgba(139, 92, 246, 0.1)' : 'transparent',
      '&:hover': {
        background: 'rgba(139, 92, 246, 0.2)'
      }
    }),
    multiValue: (base: any) => ({
      ...base,
      background: 'rgba(139, 92, 246, 0.2)'
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: 'white'
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      '&:hover': {
        background: 'rgba(239, 68, 68, 0.2)',
        color: '#ef4444'
      }
    })
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 theme-bg/60 backdrop-blur-sm"
        onClick={onClose}
      />

      <div className="relative w-full max-w-2xl max-h-[90vh] overflow-y-auto theme-bg/80 backdrop-blur-md rounded-xl border border-white/10 p-6 m-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">
            {currentHero ? t('heroes.edit_hero') : t('heroes.create_hero')}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-lg transition-all"
          >
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Avatar */}
          <div className="flex justify-center">
            <div className="relative group">
              <div className={`
                w-24 h-24 rounded-full relative
                bg-gradient-to-br from-purple-500/30 to-blue-500/30
                flex items-center justify-center
                overflow-hidden
              `}>
                {formData.avatar_file_url ? (
                  <>
                    <img
                      src={formData.avatar_file_url}
                      alt="Avatar"
                      className="w-full h-full object-cover"
                    />
                    <button
                      type="button"
                      onClick={handleRemoveAvatar}
                      className="absolute inset-0 flex items-center justify-center theme-bg/60 opacity-0 group-hover:opacity-100 transition-opacity"
                      title={t('heroes.remove_avatar')}
                    >
                      <Trash2 size={20} className="text-red-400" />
                    </button>
                  </>
                ) : (
                  <>
                    <span className="text-2xl">{formData.name?.[0] || '?'}</span>
                    <button
                      type="button"
                      onClick={handleGenerateAvatar}
                      disabled={isGenerating || !formData.uuid}
                      className={`
                        absolute inset-0 flex items-center justify-center
                        theme-bg/60 opacity-0 group-hover:opacity-100
                        transition-opacity
                        ${isGenerating || !formData.uuid ? 'cursor-not-allowed' : 'hover:theme-bg/70'}
                      `}
                      title={formData.uuid ? t('heroes.generate_avatar') : t('heroes.save_first')}
                    >
                      <Wand2 
                        size={20} 
                        className={`text-purple-300 ${isGenerating ? 'animate-pulse' : ''}`}
                      />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Rest of the form remains the same */}
          {/* Nom */}
          <div>
            <label className="block text-sm font-medium theme-text/80 mb-2">
              {t('heroes.heroes_name')} *
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={e => setFormData({...formData, name: e.target.value})}
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none transition-colors"
              required
            />
          </div>

          {/* Age */}
          <div>
            <label className="block text-sm font-medium theme-text/80 mb-2">
              {t('hero_details.age')}
            </label>
            <input
              type="number"
              value={formData.age || ''}
              onChange={e => setFormData({...formData, age: Number(e.target.value)})}
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none transition-colors"
            />
          </div>

          {/* Couleurs préférées */}
          <div>
            <label className="block text-sm font-medium theme-text/80 mb-2">
              {t('hero_details.favorite_colors')}
            </label>
            <Select
              isMulti
              options={colorsOptions(t)}
              value={colorsOptions(t).filter(option => 
                formData.favorite_colors?.includes(option.value)
              )}
              onChange={options => {
                setFormData({
                  ...formData,
                  favorite_colors: options ? options.map(opt => opt.value) : []
                });
              }}
              styles={selectStyles}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>

          {/* Activités préférées */}
          <div>
            <label className="block text-sm font-medium theme-text/80 mb-2">
              {t('hero_details.favorite_activities')}
            </label>
            <Select
              isMulti
              options={activitiesOptions(t)}
              value={activitiesOptions(t).filter(option => 
                formData.favorite_activities?.includes(option.value)
              )}
              onChange={options => {
                setFormData({
                  ...formData,
                  favorite_activities: options ? options.map(opt => opt.value) : []
                });
              }}
              styles={selectStyles}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>

          {/* Rôle */}
          <div className="space-y-2">
            <label className="block text-sm font-medium theme-text/80 mb-2">
              {t('hero_details.role')}
            </label>
            {isCustomRole ? (
              <input
                type="text"
                value={formData.role || ''}
                onChange={e => setFormData({...formData, role: e.target.value})}
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none transition-colors"
                placeholder={t('hero_details.enter_a_custom_role')}
              />
            ) : (
              <Select
                options={roleOptions(t)}
                value={roleOptions(t).find((option: Option) => option.value === formData.role)}
                onChange={option => setFormData({...formData, role: option?.value || ''})}
                styles={selectStyles}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            )}
            <button
              type="button"
              onClick={() => setIsCustomRole(!isCustomRole)}
              className="text-sm text-purple-400 hover:text-purple-300 transition-colors"
            >
              {isCustomRole ? t('hero_details.choose_from_list') : t('hero_details.enter_a_custom_role')}
            </button>
          </div>

          {/* Actions */}
          <div className="flex justify-end gap-4 pt-4 border-t border-white/10">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded-lg hover:bg-white/10 transition-all"
            >
              {t('general.cancel')}
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-purple-500/80 hover:bg-purple-500/90 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? t('general.saving') : (hero ? t('general.update') : t('general.create'))}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};