import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../ThemeProvider/ThemeProvider';
import { StoryType } from '../../../types/Story';

interface InteractiveCardProps {
    storyType: StoryType;
    onSelect: () => void; 
    disabled?: boolean;
    className?: string;
}

export const InteractiveCard: React.FC<InteractiveCardProps> = ({
    storyType, 
    onSelect,
    disabled = false,
    className = ''
}) => {
    const { t } = useTranslation();
    const { theme } = useTheme();

    const isSelected = storyType === 'interactive';

    return (
        <div
            className={`relative group ${
                isSelected
                    ? 'bg-gradient-to-r from-purple-500/40 to-pink-500/40'
                    : ''
            }  ${className}  rounded-xl`}
        >
            <div
                className={`absolute inset-0 rounded-xl blur-sm opacity-70 transition-all group-hover:opacity-100 ${
                    isSelected
                        ? 'bg-gradient-to-r from-purple-500/40 to-pink-500/40'
                        : ''
                }`}
            />
            <div
                className={`relative backdrop-blur-sm p-8 rounded-xl border transition-all h-full ${
                    isSelected
                        ? 'bg-white/10 border-white/20 hover:border-white/30'
                        : 'bg-white/5 border-white/10 hover:border-white/20'
                }`}
            >
                <div className="relative h-48 mb-6 rounded-lg overflow-hidden">
                    <div
                        className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-pink-500/10 mix-blend-overlay"
                    />
                    <img
                        src="assets/interactive-story.webp"
                        alt={t('home.story_types.interactive.title')}
                        className="w-full h-full object-cover"
                    />
                </div>
                <h3 className={`text-2xl font-bold mb-4 bg-clip-text transition-colors ${
                        isSelected
                        ? `text-transparent bg-gradient-to-r ${
                            theme === 'dark' 
                                ? 'from-purple-400 to-pink-400'
                                : 'from-purple-600 to-pink-600'  // Plus foncé pour le thème clair
                            }`
                        : 'theme-text/70'
                    }`}
                    >
                    {t('home.story_types.interactive.title')}
                </h3>
                <div>
                    <p className={`mb-6 transition-colors ${
                        isSelected
                            ? 'theme-text'
                            : 'theme-text/70'
                    }`}>
                        {t('home.story_types.interactive.description')}
                    </p>
                    <button
                        disabled={disabled || isSelected}
                        onClick={onSelect}
                        className={`
                            w-full px-6 py-3 rounded-lg border transition-all
                            ${disabled && !isSelected
                                ? 'opacity-50 cursor-not-allowed border-gray-500/30 bg-gray-500/20 hover:bg-gray-500/20'
                                : isSelected
                                    ? 'bg-gradient-to-r from-purple-500/40 to-pink-500/40 border-purple-500/50 hover:from-purple-500/50 hover:to-pink-500/50 group-hover:scale-105'
                                    : 'bg-gradient-to-r from-purple-500/20 to-pink-500/20 border-purple-500/30 hover:bg-purple-500/30 group-hover:scale-105'
                            }
                            ${disabled && !isSelected ? 'relative overflow-hidden' : ''}
                        `}
                    >
                        {/* Ajout d'un indicateur "Coming soon" pour le bouton disabled */}
                        {disabled && !isSelected && (
                            <div className="absolute inset-0 flex items-center justify-center backdrop-blur-[1px]">
                                <span className="text-sm text-gray-400 italic">
                                    {t('general.coming_soon')}
                                </span>
                            </div>
                        )}
                        
                        {/* Texte du bouton avec opacité réduite si disabled */}
                        <span className={disabled && !isSelected ? 'opacity-0' : ''}>
                            {isSelected ? t('general.selected') : t('general.select')}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
};