import React, { useEffect, useRef, useState } from 'react';
import { StoryDetails } from '../../types/StoryDetails';
import { Hero } from '../../types/Hero';
import { ChevronUp } from 'lucide-react';
import { StoryType } from '../../types/Story';
import StorySummary from './StorySummary';
import { useTheme } from '../ThemeProvider/ThemeProvider';

interface StorySummaryMobileProps {
  selectedHeroes: (Hero & { isMainCharacter?: boolean })[];
  selectedEducationalElements: string[];
  selectedValues: string[];
  selectedTone: string;
  selectedTheme: string;
  storyType: StoryType;
  currentStep: number;
  isInteractive: boolean;
  settings?: StoryDetails['settings'];
  className?: string;
}

export const StorySummaryMobile: React.FC<StorySummaryMobileProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const { currentStep } = props;
  const { theme } = useTheme();
  const prevPropsRef = useRef(props);

  // Animation auto-show sur changements
  useEffect(() => {
      const hasChanges = JSON.stringify(prevPropsRef.current) !== JSON.stringify(props);
      if (hasChanges && !isExpanded) {
          setShowBadge(true);
          const timer = setTimeout(() => {
            setShowBadge(false);
          }, 1500);
          return () => clearTimeout(timer);
      }
      prevPropsRef.current = props;
  }, [props]);

  const getCondensedSummary = () => {
      switch(currentStep) {
          case 1:
              return "Type d'histoire";
          case 2:
              return `${props.selectedHeroes.length} héros sélectionnés`;
          case 3:
              return `${props.selectedEducationalElements.length} éléments sélectionnés`;
          case 4:
              return "Paramètres de l'histoire";
          default:
              return "Résumé";
      }
  };

  return (
      <div className="fixed bottom-4 left-4 right-4 z-50">
          {/* Bouton flottant */}
          <button
              onClick={() => {
                  setIsExpanded(!isExpanded);
                  setShowBadge(false);
              }}
              className={`
                  w-full p-4 rounded-lg shadow-lg 
                  transition-all duration-300 ease-in-out
                  flex items-center justify-between
                  ${theme === 'dark' 
                      ? 'bg-black/80 border-white/10 hover:bg-black/90' 
                      : 'bg-white/80 border-black/10 hover:bg-white/90'
                  }
                  backdrop-blur-md border
              `}
          >
              <div className="flex items-center gap-2">
                  <span className={`font-medium ${theme === 'dark' ? 'text-white/90' : 'text-black/90'}`}>
                      Résumé de l'histoire
                  </span>
                  <span className={`text-sm ${theme === 'dark' ? 'text-white/60' : 'text-black/60'}`}>
                      • {getCondensedSummary()}
                  </span>
                  {showBadge && (
                      <span className="px-2 py-0.5 text-xs bg-purple-500 text-white rounded-full animate-pulse">
                          Mis à jour
                      </span>
                  )}
              </div>
              <ChevronUp 
                  className={`
                      transform transition-transform duration-300
                      ${isExpanded ? 'rotate-180' : ''} 
                      ${theme === 'dark' ? 'text-white/70' : 'text-black/70'}
                  `}
                  size={20}
              />
          </button>

          {/* Panel déplié avec animation */}
          <div className={`
              mt-2 rounded-lg shadow-lg overflow-hidden
              transition-all duration-300 ease-in-out
              ${isExpanded ? 'max-h-[70vh] opacity-100' : 'max-h-0 opacity-0'}
              ${theme === 'dark' 
                  ? 'bg-black/80 border-white/10' 
                  : 'bg-white/80 border-black/10'
              }
              backdrop-blur-md border
          `}>
              <div className="p-4 overflow-y-auto">
                  <StorySummary {...props} className="!static !w-full" />
              </div>
          </div>
      </div>
  );
};