// src/components/Layout/Layout.tsx
import React from 'react';
import { useResponsive } from '../MobileProvider/MobileProvider';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, className = '' }) => {
  const { containerClassName } = useResponsive();

  return (
    <div className={`${containerClassName} ${className} max-w-full`.trim()}>
      {children}
    </div>
  );
};