// src/components/AppLayout/AppLayout.tsx
import React from 'react';
import { SideMenu } from '../SideMenu/SideMenu';

interface AppLayoutProps {
  children: React.ReactNode;
}

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen theme-bg">
      <SideMenu />
      <main className="min-h-screen transition-all duration-300 w-full">
        {children}
      </main>
    </div>
  );
};