import React from 'react';
import { useTranslation } from 'react-i18next';
import { Crown, Users, Heart, BookMarked, GraduationCap } from 'lucide-react';
import { ReaderSidebarProps } from './types/ReaderControlsTypes';
import { Loading } from '../../Loading/Loading';
import { Hero } from '../../../types/Hero';

export const ReaderSidebar: React.FC<ReaderSidebarProps> = ({
  story,
  isDarkTheme,
  isAuthenticated,
  userProfile,
  onGenerateIllustration,
  loadingIllustration
}) => {
  const { t } = useTranslation();

    return (
        <aside className={`
            hidden lg:block w-80 border-l
            ${isDarkTheme 
                ? 'bg-black/90 border-white/10' 
                : 'bg-white border-gray-200'} // Fond blanc plein
            `}
        >
        <div className="h-full flex flex-col p-4">
        {/* Illustration */}
        {story.details.illustration_file_url ? (
            <div className="space-y-4 -mx-2">
            <img 
                src={story.details.illustration_file_url} 
                alt={story.title} 
                className="w-full object-cover"
            />
            </div>
        ) : isAuthenticated && userProfile && userProfile.uuid === story.creator_user_id ? (
            <div className="space-y-4">
            <button
                onClick={() => onGenerateIllustration(story)}
                disabled={loadingIllustration}
                className="w-full px-6 py-3 bg-gradient-to-r from-purple-500/80 to-blue-500/80 rounded-lg 
                        hover:from-purple-500/90 hover:to-blue-500/90 transition-all text-white
                        disabled:opacity-50 disabled:cursor-not-allowed"
            >
                {loadingIllustration ? (
                <Loading size={24} variant="gradient" withText={false} />
                ) : t('ui.generate_illustration')}
            </button>
            </div>
        ) : null}

            {/* Résumé */}
            {story.summary && (
                <div className="space-y-4">
                    <h2 className={`flex items-center gap-2 text-lg font-medium ${
                        isDarkTheme ? 'text-white/90' : 'text-gray-900'
                    }`}>
                        {t('story_details.summary')}
                    </h2>
                    <p className={`text-sm leading-relaxed ${
                        isDarkTheme ? 'text-white/70' : 'text-gray-600'
                    }`}>
                        {story.summary}
                    </p>
                </div>
            )}

            {/* Héros */}
            {story.details.heroes && story.details.heroes.length > 0 && (
                <div className={`pt-4 space-y-4 ${
                    isDarkTheme ? 'border-white/10' : 'border-gray-200'
                }`}>
                    <h2 className={`flex items-center gap-2 text-lg font-medium ${
                        isDarkTheme ? 'text-white/90' : 'text-gray-900'
                    }`}>
                        <Users size={20} />
                        {t('heroes.characters')}
                    </h2>
                    <div className="space-y-3">
                        {story.details.heroes.map((hero: Hero, index: number) => (
                        <div 
                            key={index} 
                            className={`p-3 rounded-lg border flex items-center gap-3 ${
                            isDarkTheme 
                                ? 'bg-black/20 border-white/10' 
                                : 'bg-white/60 border-gray-200'
                            }`}
                        >
                            <div className="w-8 h-8 rounded-full bg-gradient-to-br from-purple-500/30 to-blue-500/30 
                                        flex items-center justify-center shrink-0">
                                {hero.avatar_file_url ? (
                                    <img
                                    src={hero.avatar_file_url}
                                    alt={hero.name}
                                    className="w-full h-full rounded-full object-cover"
                                    />
                                ) : (
                                    <span className={`text-sm ${
                                    isDarkTheme ? 'text-white/70' : 'text-gray-600'
                                    }`}>
                                    {hero.name[0]}
                                    </span>
                                )}
                            </div>

                            <span className={isDarkTheme ? 'text-white/90' : 'text-gray-900'}>
                                {hero.name}
                            </span>

                            {index === 0 && (
                            <div className="bg-blue-500/20 p-1.5 rounded-full shrink-0">
                                <Crown size={14} className="text-blue-400" />
                            </div>
                            )}
                        </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Thème */}
            {story.details.theme && (
                <div className={`pt-4 space-y-4 ${
                    isDarkTheme ? 'border-white/10' : 'border-gray-200'
                }`}>
                    <h2 className={`flex items-center gap-2 text-lg font-medium ${
                        isDarkTheme ? 'text-white/90' : 'text-gray-900'
                    }`}>
                        <BookMarked size={20} />
                        {t('story_elements.theme')}
                    </h2>
                    <div className="flex flex-wrap gap-2">
                        <p className={`text-sm ${
                        isDarkTheme ? 'text-white/70' : 'text-gray-600'
                        }`}>
                        {t(`options.themes.${story.details.theme}`)}
                        </p>
                    </div>
                </div>
            )}

            {/* Valeurs morales */}
            {Array.isArray(story.details.moral_values) && story.details.moral_values.length > 0 && (
                <div className={`pt-4 space-y-4 ${
                    isDarkTheme ? 'border-white/10' : 'border-gray-200'
                }`}>
                    <h2 className={`flex items-center gap-2 text-lg font-medium ${
                        isDarkTheme ? 'text-white/90' : 'text-gray-900'
                    }`}>
                        <Heart size={20} />
                        {t('story_elements.moral_values')}
                    </h2>
                    <div className="flex flex-wrap gap-2">
                        {story.details.moral_values.map((value, index) => (
                        <span
                            key={index}
                            className="px-2 py-1 text-xs rounded-full bg-red-500/10 text-red-300"
                        >
                            {t(`options.moralValues.${value}`)}
                        </span>
                        ))}
                    </div>
                </div>
            )}

            {/* Éléments éducatifs */}
            {Array.isArray(story.details.educational_elements) && story.details.educational_elements.length > 0 && (
                <div className={`pt-4 space-y-4 ${
                isDarkTheme ? 'border-white/10' : 'border-gray-200'
                }`}>
                    <h2 className={`flex items-center gap-2 text-lg font-medium ${
                        isDarkTheme ? 'text-white/90' : 'text-gray-900'
                    }`}>
                        <GraduationCap size={20} />
                        {t('story_elements.educational_elements')}
                    </h2>
                    <div className="flex flex-wrap gap-2">
                        {story.details.educational_elements.map((element, index) => (
                        <span
                            key={index}
                            className="px-2 py-1 text-xs rounded-full bg-yellow-500/10 text-yellow-300"
                        >
                            {t(`options.educationalElements.${element}`)}
                        </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    </aside>
  );
};
