import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../api/auth-users';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../components/AuthContext/AuthContext';
import { TextLoading } from '../components/Loading/TextLoading';
import { Check, X } from 'lucide-react';
import { Layout } from '../components/Layout/Layout';

export const SignupPage: React.FC = () => {
  const { setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Validation du mot de passe
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    number: false,
    special: false,
    uppercase: false,
  });

  useEffect(() => {
    setPasswordValidation({
      length: password.length >= 8,
      number: /\d/.test(password),
      special: /[!@#$%^&*]/.test(password),
      uppercase: /[A-Z]/.test(password),
    });
  }, [password]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage(t('passwords_do_not_match'));
      return;
    }

    if (!Object.values(passwordValidation).every(Boolean)) {
      setMessage(t('password_requirements_not_met'));
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      const response = await auth(email, password, 'signup');
      if (response.token !== undefined) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("refresh_token", response.refresh_token);
        setIsAuthenticated(true);
        window.location.href = '/stories';
      } else {
        setMessage(response.data.message);
      }
    } catch (error: any) {
      setMessage(error.response?.data?.message || 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const ValidationItem = ({ satisfied, text }: { satisfied: boolean; text: string }) => (
    <div className="flex items-center gap-2 text-sm">
      {satisfied ? (
        <Check size={16} className="text-green-400" />
      ) : (
        <X size={16} className="theme-text/40" />
      )}
      <span className={satisfied ? 'text-green-400' : 'theme-text/40'}>
        {text}
      </span>
    </div>
  );

  return (
    <Layout>
      <div className="min-h-screen theme-bg theme-text">
        {/* Background effects */}
        <div className="fixed inset-0 opacity-30">
          <div className="absolute top-0 right-0 w-[600px] h-[600px] rounded-full bg-purple-500/20 blur-[150px] mix-blend-screen" />
          <div className="absolute bottom-0 left-0 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[150px] mix-blend-screen" />
        </div>

        <div className="relative z-10 max-w-md mx-auto px-4 py-16">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white">
              {loading ? 
                <TextLoading text={t('create_account')} variant="shine" className="text-3xl font-bold" /> 
                : t('auth.create_account')
              }
            </h1>
            <p className="theme-text-secondary">
              {t('auth.signup_welcome_message')}
            </p>
          </div>

          <div className="backdrop-blur-sm bg-white/5 p-8 rounded-xl border border-white/10">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium theme-text/80 mb-2">
                  {t('auth.email')}
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 theme-card border border-white/10 rounded-lg focus:outline-none focus:border-purple-500/50 transition-colors"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium theme-text/80 mb-2">
                  {t('auth.password')}
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 theme-card border border-white/10 rounded-lg focus:outline-none focus:border-purple-500/50 transition-colors"
                  required
                />
                
                {/* Password requirements */}
                <div className="mt-3 p-3 theme-card rounded-lg space-y-2">
                  <ValidationItem 
                    satisfied={passwordValidation.length} 
                    text={t('auth.password_length')}
                  />
                  <ValidationItem 
                    satisfied={passwordValidation.uppercase} 
                    text={t('auth.password_uppercase')}
                  />
                  <ValidationItem 
                    satisfied={passwordValidation.number} 
                    text={t('auth.password_number')}
                  />
                  <ValidationItem 
                    satisfied={passwordValidation.special} 
                    text={t('auth.password_special')}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium theme-text/80 mb-2">
                  {t('auth.confirm_password')}
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-4 py-2 theme-card border border-white/10 rounded-lg focus:outline-none focus:border-purple-500/50 transition-colors"
                  required
                />
              </div>

              {message && (
                <div className="p-3 rounded-lg bg-red-500/10 border border-red-500/20 text-red-400 text-sm">
                  {message}
                </div>
              )}

              <button
                type="submit"
                disabled={loading || !Object.values(passwordValidation).every(Boolean) || password !== confirmPassword}
                className="w-full py-3 bg-gradient-to-r from-purple-500 to-blue-500 rounded-lg font-medium 
                         hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? t('loading') : t('auth.create_account')}
              </button>
            </form>

            <div className="mt-6 pt-6 border-t border-white/10 text-center">
              <p className="theme-text-secondary">
                {t('auth.switch_to_login')}{' '}
                <Link 
                  to="/login" 
                  className="text-purple-400 hover:text-purple-300 transition-colors"
                >
                  {t('auth.login')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};