import { X } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface PasswordModalProps {
    onClose: () => void;
    onConfirm: (currentPassword: string, newPassword: string) => Promise<void>;
}
  
export const PasswordAccountModal: React.FC<PasswordModalProps> = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) return;
    
    setLoading(true);
    try {
      await onConfirm(currentPassword, newPassword);
      onClose();
    } catch (error) {
      console.error('Error updating password:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 theme-bg/60 backdrop-blur-sm" onClick={onClose} />
      <div className="relative w-full max-w-md theme-bg/80 backdrop-blur-md rounded-xl border border-white/10 p-6 m-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">{t('account.modify_password')}</h2>
          <button onClick={onClose} className="p-2 hover:bg-white/10 rounded-lg transition-all">
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm theme-text/80 mb-2">{t('account.current_password')}</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none"
              required
            />
          </div>

          <div>
            <label className="block text-sm theme-text/80 mb-2">{t('account.new_password')}</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none"
              required
            />
          </div>

          <div>
            <label className="block text-sm theme-text/80 mb-2">{t('account.confirm_password')}</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none"
              required
            />
            {newPassword !== confirmPassword && (
              <p className="mt-1 text-sm text-red-400">Les mots de passe ne correspondent pas</p>
            )}
          </div>

          <div className="flex justify-end gap-4 pt-4 border-t border-white/10">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded-lg hover:bg-white/10 transition-all"
            >
              {t('general.cancel')}
            </button>
            <button
              type="submit"
              disabled={loading || newPassword !== confirmPassword}
              className="px-4 py-2 bg-purple-500/20 hover:bg-purple-500/30 rounded-lg transition-all disabled:opacity-50"
            >
              {loading ? t('general.updating') : t('general.update')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
