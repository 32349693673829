import { useTranslation } from "react-i18next";
import { useTheme } from "../ThemeProvider/ThemeProvider";
import { useNavigate } from "react-router-dom";
import { ClassicCard } from "../Story/TypeCard/ClassicCard";
import { InteractiveCard } from "../Story/TypeCard/InteractiveCard";

export const MainContent: React.FC = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const navigate = useNavigate();
  
    return (
        <main className={`pt-16 min-h-screen theme-bg theme-text relative overflow-x-hidden ${
            theme === 'light' ? 'bg-gradient-to-b from-gray-50 to-white' : 'bg-[#0A0A0F]'
          }`}>
            {/* Background gradients */}
            <div className={`fixed inset-0 ${theme === 'light' ? 'opacity-20' : 'opacity-40'}`}>
              <div className="absolute top-0 left-0 w-[800px] h-[800px] rounded-full bg-gradient-to-r from-purple-500/30 to-blue-500/30 blur-[150px] mix-blend-screen" />
              <div className="absolute top-[20%] right-0 w-[600px] h-[600px] rounded-full bg-gradient-to-r from-blue-500/30 to-teal-500/30 blur-[150px] mix-blend-screen" />
              <div className="absolute bottom-0 left-[20%] w-[700px] h-[700px] rounded-full bg-gradient-to-r from-teal-500/30 to-purple-500/30 blur-[150px] mix-blend-screen" />
            </div>
    
            {/* Hero Section */}
            <section className="relative min-h-screen flex items-center">
              <div className="fixed inset-0 w-full h-full">
                <div className={`absolute inset-0 bg-gradient-to-b ${
                  theme === 'light' 
                    ? 'from-white/80 via-white/70 to-white/80'
                    : 'from-black/80 via-black/70 to-black/80'
                } z-10`} />
              </div>
    
              <div className="relative z-20 max-w-7xl mx-auto px-6 py-32 grid lg:grid-cols-2 gap-12 items-center">
                <div className="text-left">
                  <h1 className="text-5xl sm:text-7xl font-bold mb-6 theme-gradient-text">
                    {t('general.infinistories')}
                  </h1>
                  <p className="text-xl sm:text-2xl theme-text-secondary mb-12">
                    {t('home.hero.tagline')}
                  </p>
                  <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                    <button
                      onClick={() => navigate('/create')}
                      className="px-8 py-4 theme-purple-bg theme-border-purple backdrop-blur-sm rounded-lg hover:theme-purple-hover transition-all hover:scale-105">
                      {t('home.hero.start_button')}
                    </button>
                  </div>
                </div>
                <div className="lg:block relative w-full h-64 md:h-96 lg:h-[600px] rounded-2xl overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-blue-500/10 mix-blend-overlay" />
                  <img 
                    src='assets/home-img-0.webp'
                    alt={t('home.hero.image_alt')}
                    className="w-full h-full object-cover object-center rounded-2xl"
                  />
                </div>
              </div>
            </section>
    
            {/* Story Types Section */}
            <section className="relative py-24">
              <div className="max-w-7xl mx-auto px-6">
                <div className="grid lg:grid-cols-2 gap-8">
                  <ClassicCard
                    storyType={null} 
                    onSelect={() => navigate('/create?type=classic&step=2')} 
                    className='bg-gradient-to-r from-blue-500/20 to-teal-500/20'
                  />
                  <InteractiveCard
                    storyType={null} 
                    onSelect={() => navigate('/create?type=interactive&step=2')} 
                    disabled={true}
                    className='bg-gradient-to-r from-purple-500/20 to-pink-500/20'
                  />
                </div>
              </div>
            </section>
    
            {/* Features Section */}
            <section className="relative py-24">
              <div className="max-w-7xl mx-auto px-6">
                <h2 className="text-3xl sm:text-4xl font-bold text-center mb-6 theme-gradient-text">
                  {t('home.features.title')}
                </h2>
                <p className="text-xl theme-text-secondary text-center mb-16 max-w-3xl mx-auto">
                  {t('home.features.subtitle')}
                </p>
                <div className="grid lg:grid-cols-3 gap-8">
                  {[
                    {
                      title: t('home.features.cards.personalized.title'),
                      description: t('home.features.cards.personalized.description'),
                      illustrationFileName: "home-img-1.webp"
                    },
                    {
                      title: t('home.features.cards.learning.title'),
                      description: t('home.features.cards.learning.description'),
                      illustrationFileName: "home-img-2.webp",
                      highlighted: true
                    },
                    {
                      title: t('home.features.cards.infinite.title'),
                      description: t('home.features.cards.infinite.description'),
                      illustrationFileName: "home-img-3.webp"
                    }
                  ].map((feature, index) => (
                    <div key={index} className="relative group">
                      {feature.highlighted && (
                        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-blue-500/20 to-teal-500/20 rounded-xl -m-1 transition-all group-hover:scale-105" />
                      )}
                      <div className="backdrop-blur-sm theme-card p-6 rounded-xl theme-border hover:theme-border-hover transition-all relative">
                        <div className="relative h-48 mb-6 rounded-lg overflow-hidden">
                          <div className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-blue-500/10 mix-blend-overlay" />
                          <img 
                            src={`assets/${feature.illustrationFileName}`}
                            alt={feature.title}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <h3 className="text-xl font-bold mb-3 theme-text">{feature.title}</h3>
                        <p className="theme-text-secondary">{feature.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
    
            {/* How it works section */}
            <section className="relative py-24 overflow-hidden">
              <div className="max-w-7xl mx-auto px-6">
                <h2 className="text-4xl font-bold text-center mb-6 theme-gradient-text">
                  {t('home.how_it_works.title')}
                </h2>
                <p className="text-xl theme-text-secondary text-center mb-16 max-w-3xl mx-auto">
                  {t('home.how_it_works.subtitle')}
                </p>
                <div className="grid lg:grid-cols-3 gap-12">
                  {[
                    {
                      step: "1",
                      title: t('home.how_it_works.steps.customize.title'),
                      description: t('home.how_it_works.steps.customize.description')
                    },
                    {
                      step: "2",
                      title: t('home.how_it_works.steps.choose.title'),
                      description: t('home.how_it_works.steps.choose.description')
                    },
                    {
                      step: "3",
                      title: t('home.how_it_works.steps.explore.title'),
                      description: t('home.how_it_works.steps.explore.description')
                    }
                  ].map((item, index) => (
                    <div key={index} className="relative group">
                      <div className={`
                        absolute -inset-4 bg-gradient-to-r from-purple-500/20 via-pink-500/20 to-blue-500/20 rounded-xl blur-sm 
                        ${theme === 'light' ? 'opacity-50' : 'opacity-70'} 
                        transition-opacity group-hover:opacity-100`}
                        style={{ transform: 'rotate(-2deg)' }}
                      />
                      <div className="relative backdrop-blur-sm theme-card p-8 rounded-xl theme-border transition-transform group-hover:scale-[1.02]">
                        <span className="text-5xl font-bold theme-gradient-text absolute -top-6 -left-4">
                          {item.step}
                          </span>
                        <h3 className="text-2xl font-bold mb-4 theme-text">{item.title}</h3>
                        <p className="theme-text-secondary">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
    
            {/* Prix Section */}
            <section id="pricing" className="relative py-24 overflow-hidden">
              <div className="max-w-7xl mx-auto px-6">
                <h2 className="text-4xl font-bold text-center mb-6 theme-gradient-text">
                  {t('home.pricing.title')}
                </h2>
                <p className="text-xl theme-text-secondary text-center mb-16 max-w-2xl mx-auto">
                  {t('home.pricing.subtitle')}
                </p>
    
                <div className="grid lg:grid-cols-3 gap-8">
                  {[
                    {
                      name: t('home.pricing.plans.discovery.name'),
                      price: t('home.pricing.plans.discovery.price'),
                      features: [
                        t('home.pricing.plans.discovery.features.stories'),
                        t('home.pricing.plans.discovery.features.personalization'),
                        t('home.pricing.plans.discovery.features.library')
                      ],
                      highlighted: false,
                      gradient: "from-blue-500/20 to-teal-500/20"
                    },
                    {
                      name: t('home.pricing.plans.family.name'),
                      price: t('home.pricing.plans.family.price'),
                      features: [
                        t('home.pricing.plans.family.features.stories'),
                        t('home.pricing.plans.family.features.personalization'),
                        t('home.pricing.plans.family.features.library'),
                        t('home.pricing.plans.family.features.offline'),
                        t('home.pricing.plans.family.features.profiles')
                      ],
                      highlighted: true,
                      gradient: "from-purple-500/20 via-pink-500/20 to-blue-500/20",
                      badge: t('home.pricing.popular_badge')
                    },
                    {
                      name: t('home.pricing.plans.premium.name'),
                      price: t('home.pricing.plans.premium.price'),
                      features: [
                        t('home.pricing.plans.premium.features.family'),
                        t('home.pricing.plans.premium.features.custom'),
                        t('home.pricing.plans.premium.features.themes'),
                        t('home.pricing.plans.premium.features.support'),
                        t('home.pricing.plans.premium.features.early')
                      ],
                      highlighted: false,
                      gradient: "from-purple-500/20 to-pink-500/20"
                    }
                  ].map((plan, index) => (
                    <div key={index} className="relative group">
                      <div className={`
                        absolute inset-0 rounded-xl -m-1 transition-all duration-500
                        opacity-0 group-hover:opacity-100 blur-sm
                        bg-gradient-to-r ${plan.gradient}
                      `} />
    
                      <div className={`
                        relative backdrop-blur-sm p-8 rounded-xl border transition-all duration-300
                        ${plan.highlighted 
                          ? 'theme-card-highlighted theme-border-purple shadow-lg shadow-purple-500/10' 
                          : 'theme-card theme-border hover:theme-border-hover'}
                      `}>
                        {plan.badge && (
                          <div className="absolute -top-4 left-1/2 -translate-x-1/2 px-4 py-1 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full text-sm font-medium theme-text">
                            {plan.badge}
                          </div>
                        )}
    
                        <h3 className="text-2xl font-bold mb-2 theme-text">{plan.name}</h3>
                        <div className="text-3xl font-bold mb-6 theme-gradient-text">
                          {plan.price}
                        </div>
    
                        <ul className="space-y-4 mb-8">
                          {plan.features.map((feature, featureIndex) => (
                            <li key={featureIndex} className="flex items-center gap-3">
                              <span className="h-1.5 w-1.5 rounded-full bg-gradient-to-r from-purple-500 to-blue-500" />
                              <span className="theme-text-secondary">{feature}</span>
                            </li>
                          ))}
                        </ul>
    
                        <button className={`
                            w-full py-3 rounded-lg transition-all duration-300 
                            ${plan.highlighted
                            ? 'bg-gradient-to-r from-purple-500 to-blue-500 hover:opacity-90 theme-text'
                            : 'theme-card hover:theme-card-hover theme-border hover:theme-border-hover'}
                            `}
                            disabled={true}>
                            {t('home.pricing.choose_plan')}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
    
            {/* Témoignages Section */}
            <section className="relative py-24">
              <div className="max-w-7xl mx-auto px-6">
                <h2 className="text-4xl font-bold text-center mb-6 theme-gradient-text">
                  {t('home.testimonials.title')}
                </h2>
                <p className="text-xl theme-text-secondary text-center mb-16 max-w-2xl mx-auto">
                  {t('home.testimonials.subtitle')}
                </p>
    
                <div className="grid lg:grid-cols-2 gap-8">
                  {[
                    {
                      text: t('home.testimonials.reviews.1.text'),
                      author: t('home.testimonials.reviews.1.author'),
                      role: t('home.testimonials.reviews.1.role'),
                      gradient: "from-purple-500/10 to-blue-500/10"
                    },
                    {
                      text: t('home.testimonials.reviews.2.text'),
                      author: t('home.testimonials.reviews.2.author'),
                      role: t('home.testimonials.reviews.2.role'),
                      gradient: "from-blue-500/10 to-teal-500/10"
                    }
                  ].map((testimonial, index) => (
                    <div key={index} className="relative group">
                      <div className={`
                        absolute inset-0 rounded-xl transition-all duration-500
                        opacity-0 group-hover:opacity-100 blur-sm
                        bg-gradient-to-r ${testimonial.gradient}
                      `} />
    
                      <div className="relative backdrop-blur-sm theme-card p-8 rounded-xl theme-border hover:theme-border-hover transition-all">
                        <div className="absolute -left-3 -top-3 text-4xl theme-text-secondary">"</div>
                        <p className="text-lg mb-6 theme-text relative z-10">{testimonial.text}</p>
                        <div className="relative z-10">
                          <div className="font-bold theme-text">{testimonial.author}</div>
                          <div className="theme-text-secondary">{testimonial.role}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
    
            {/* CTA Final */}
            <section className="relative py-24 overflow-hidden">
              <div className="absolute inset-0 theme-card" />
              <div className="relative max-w-4xl mx-auto text-center px-6">
                <h2 className="text-3xl sm:text-4xl font-bold mb-6 theme-gradient-text">
                  {t('home.cta.title')}
                </h2>
                <p className="text-lg sm:text-xl theme-text-secondary mb-8">
                  {t('home.cta.subtitle')}
                </p>
                <button
                  onClick={() => navigate('/create')}
                  className="px-8 py-4 bg-gradient-to-r from-purple-500 to-blue-500 backdrop-blur-sm rounded-lg transition-all hover:opacity-90 theme-text"
                >
                  {t('home.cta.button')}
                </button>
              </div>
            </section>
          </main>
    );
};