import React, { useState, useEffect } from 'react';
import { Heart, Backpack, Scroll, Shield, Zap, Map, Send } from 'lucide-react';
import { GameState } from '../../types/GameState';
import { Choice } from '../../types/Choice';
import { Message } from '../../types/Message';
import { GameStats } from '../../types/GameStats';
import { generateNextScene, loadProgress, saveProgress } from '../../api/stories';
import { Story } from '../../types/Story';
import { t } from 'i18next';

const GameInterface: React.FC<{ 
  story: Story,
  gameIsStarted: boolean,
}> = ({ story, gameIsStarted }) => {
  const [error, setError] = useState<string | null>(null);
  const [loadingInteractive, setLoading] = useState<boolean>(true);
  const [gameStarted, setGameStarted] = useState(gameIsStarted);
  const [gameState, setGameState] = useState<GameState>({
    health: 50,
    maxHealth: 100,
    magic: 50,
    maxMagic: 100,
    experience: 10,
    inventory: [],
    stats: {
      force: 10,
      sagesse: 10,
      agilité: 10
    }
  });

  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    if (!gameStarted) return;
    const initializeStory = async () => {
      try {
        // Tente de charger une partie sauvegardée
        const savedProgress = await loadProgress(story.uuid)
        
        if (savedProgress) {
          setGameState(savedProgress.game_state);
          setMessages(savedProgress.messages);
        } else {
          // Commence une nouvelle histoire
          const initialScene = await generateNextScene(
            "début de l'histoire",
            gameState
          );
          if(initialScene)
          setMessages([initialScene]);
        }
      } catch (err) {
        setError("Erreur lors du chargement de l'histoire");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    
    initializeStory();
  }, [gameStarted]);

  const performAction = async (choice: Choice) => {
    try {
      setLoading(true);
      const [statKey, required] = Object.entries(choice.requirements)[0];
      // Vérifie que la stat existe dans gameState.stats
      const statValue = gameState.stats[statKey as keyof GameStats];
      const success = typeof statValue === 'number' && statValue >= required;
      
      
      // Applique les conséquences du choix
      const consequences = success ? choice.consequences.success : choice.consequences.failure;
      
      const newGameState = {
        ...gameState,
        health: Math.max(0, gameState.health + consequences.health),
        magic: Math.max(0, gameState.magic + consequences.magic),
        experience: gameState.experience + consequences.experience,
        inventory: success && consequences.loot 
          ? [...gameState.inventory, ...consequences.loot]
          : gameState.inventory
      };
      
      setGameState(newGameState);
      
      // Ajoute le choix du joueur aux messages
      const playerMessage: Message = {
        type: 'player',
        content: choice.text
      };
      
      setMessages(prev => [...prev, playerMessage]);
      
      // Génère la suite de l'histoire
      const nextScene = await generateNextScene(choice.text, newGameState);
      setMessages(prev => [...prev, nextScene]);
      
      await saveProgress({
        storyUuid: story.uuid,
        gameState: newGameState,
        messages: [...messages, playerMessage, nextScene],
        currentChapter: 1
      });
      
    } catch (err) {
      setError("Une erreur est survenue lors de l'action");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getAmbianceStyles = (ambiance: string) => {
    switch (ambiance) {
      case 'dark':
        return 'from-black/90 via-black/85 to-black/90';
      case 'light':
        return 'from-black/80 via-black/75 to-black/80';
      case 'mystical':
      default:
        return 'from-black/90 via-black/85 to-black/90';
    }
  };

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen theme-bg">
        <div className="bg-red-500/10 text-red-300 p-4 rounded-lg backdrop-blur-sm">
          {error}
        </div>
      </div>
    );
  }

  if (!gameStarted) {
    return (
      <div className="relative min-h-screen overflow-hidden">
        {/* Image de fond avec effet sombre */}
        <div 
          className="fixed inset-0 w-full h-full transition-all duration-1000 ease-in-out"
          style={{
            backgroundImage: "url('/assets/background2.webp')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            filter: 'brightness(0.3)'
          }}
        />

        {/* Overlay gradient plus subtil */}
        <div className="fixed inset-0 bg-gradient-to-b from-black/95 via-black/90 to-black/95" />

        {/* Contenu de l'écran d'accueil - Maintenant parfaitement centré */}
        <div className="relative z-10 min-h-screen mx-auto max-w-screen-xl flex flex-col items-center justify-center p-8 text-center">
          <h1 className="text-6xl font-bold mb-4 theme-text/90 tracking-wider">
            Chroniques de Sylvarmonie
          </h1>
          
          <p className="max-w-2xl mb-12 text-lg theme-text/70 leading-relaxed">
            Plongez dans un monde où la magie et le destin s'entremêlent. 
            En tant que Gardien des Frontières, votre quête vous mènera aux confins 
            des royaumes connus et au-delà.
          </p>

          <button
            onClick={() => setGameStarted(true)}
            className="group relative px-8 py-4 text-xl"
          >
            {/* Fond du bouton plus subtil */}
            <div className="absolute inset-0 theme-bg/60 group-hover:theme-bg/40 rounded-lg transition-all duration-300 backdrop-blur-sm" />
            
            {/* Bordure subtile */}
            <div className="absolute inset-0 rounded-lg border border-white/10 group-hover:border-white/20 transition-colors duration-300" />
            
            {/* Effet de glow plus doux */}
            <div className="absolute inset-0 rounded-lg opacity-0 group-hover:opacity-20 bg-white blur-xl transition-all duration-300" />
            
            {/* Texte du bouton */}
            <span className="relative theme-text/90 font-medium tracking-wider">
              Commencer l'Aventure
            </span>

            {/* Lignes décoratives plus subtiles */}
            <div className="absolute left-0 right-0 bottom-0 h-px bg-gradient-to-r from-transparent via-white/20 to-transparent transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
            <div className="absolute left-0 right-0 top-0 h-px bg-gradient-to-r from-transparent via-white/20 to-transparent transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
          </button>

          <p className="mt-12 text-sm theme-text/40 max-w-md">
            "Dans les temps anciens, quand la magie coulait librement entre les mondes,
            les Gardiens veillaient sur l'équilibre des royaumes..."
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen overflow-hidden">
      <div 
        className="fixed inset-0 w-full h-full transition-all duration-1000 ease-in-out"
        style={{
          backgroundImage: `url(${story.details.illustration_file_url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          filter: 'brightness(0.45)'
        }}
      />

      <div className="fixed inset-0 bg-gradient-to-b from-black/85 via-black/80 to-black/85" />

      <div className="relative flex min-h-screen">
        {/* Zone principale */}
        <div className="flex-1 flex flex-col p-6">
          <div className="flex-1 overflow-y-auto custom-scrollbar">
            <div className="max-w-3xl mx-auto space-y-6">
              {messages.map((msg, index) => (
                <div 
                  key={index}
                  className={`
                    backdrop-blur-sm rounded-xl p-6 
                    ${msg.type === 'narrator' 
                      ? 'theme-bg/25 border border-white/10 hover:border-purple-500/10' 
                      : 'theme-bg/20 ml-auto border border-purple-500/10'}
                    transform transition-all duration-300 hover:scale-[1.01]
                  `}
                >
                  <p className="leading-relaxed theme-text/90">{msg.content}</p>
                  {msg.choices && !loadingInteractive && (
                    <div className="mt-4 space-y-2">
                      {msg.choices.map((choice, choiceIndex) => {
                        const [statKey, required] = Object.entries(choice.requirements)[0];
                        const statValue = gameState.stats[statKey as keyof GameStats];
                        const canChoose = typeof statValue === 'number' && statValue >= required;
                        
                        return (
                          <button
                            key={choiceIndex}
                            onClick={() => performAction(choice)}
                            disabled={loadingInteractive || !canChoose}
                            className={`
                              w-full p-4 rounded-lg 
                              theme-card
                              border border-white/10
                              backdrop-blur-sm
                              transition-all duration-300
                              ${canChoose 
                                ? 'hover:bg-purple-500/5 hover:border-purple-500/20' 
                                : 'opacity-50 cursor-not-allowed'}
                            `}
                          >
                            <span className="block theme-text/90">{choice.text}</span>
                            <span className="text-sm block mt-1 theme-text-secondary">
                            {t('required')} {statKey}: {required}
                            </span>
                          </button>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
              {loadingInteractive && (
                <div className="flex justify-center p-4">
                  <div className="w-8 h-8 rounded-full border-2 border-purple-500/30 border-t-transparent animate-spin" />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Sidebar droite */}
        <div className="w-80 theme-bg/25 backdrop-blur-md border-l border-white/10 p-6 flex flex-col gap-6">
          {/* Location */}
          <div className="flex items-center gap-3 pb-4 border-b border-white/10">
            <Map className="theme-text/70" />
            <div>
              <h2 className="text-xl font-semibold theme-text/95">{story.title}</h2>
            </div>
          </div>

          {/* Stats avec nouvelles couleurs */}
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="flex justify-between text-sm theme-text/80">
                <span>{t('health')}</span>
                <span>{gameState.health}/{gameState.maxHealth}</span>
              </div>
              <div className="h-1.5 theme-bg/40 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-green-400/40 to-green-500/50 transition-all duration-300 shadow-[0_0_8px_rgba(74,222,128,0.3)]"
                  style={{width: `${(gameState.health/gameState.maxHealth)*100}%`}}
                />
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex justify-between text-sm theme-text/80">
                <span>{t('magic')}</span>
                <span>{gameState.magic}/{gameState.maxMagic}</span>
              </div>
              <div className="h-1.5 theme-bg/40 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-amber-400/40 to-amber-500/50 transition-all duration-300 shadow-[0_0_8px_rgba(245,158,11,0.3)]"
                  style={{width: `${(gameState.magic/gameState.maxMagic)*100}%`}}
                />
              </div>
            </div>
          </div>

          {/* Stats */}
          <div className="theme-bg/20 rounded-xl p-4 space-y-3 border border-white/10">
            {Object.entries(gameState.stats).map(([stat, value]) => (
              <div 
                key={stat}
                className="flex items-center justify-between p-2 rounded-lg hover:bg-purple-500/5 transition-all"
              >
                <span className="theme-text/80 capitalize">{stat}</span>
                <span className="theme-text/90">{value}</span>
              </div>
            ))}
          </div>

          {/* Inventaire */}
          <div className="flex-1 theme-bg/20 rounded-xl p-4 border border-white/10">
            <div className="flex items-center gap-2 mb-4">
              <Backpack className="theme-text/70" />
              <h3 className="theme-text/90 font-medium">{t('inventory')}</h3>
            </div>
            <div className="space-y-2 max-h-[200px] overflow-y-auto custom-scrollbar">
              {gameState.inventory.map((item, index) => (
                <div 
                  key={index}
                  className="p-2 theme-card rounded-lg hover:bg-purple-500/5 transition-all cursor-pointer text-sm theme-text/80"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameInterface;