// src/components/SideMenu/SideMenuContext.tsx
import React, { createContext, useContext, useState } from 'react';

interface SideMenuContextType {
  isCollapsed: boolean;
  isMobile: boolean;
  setIsCollapsed: (value: boolean) => void;
  setIsMobile: (value: boolean) => void;
}

const SideMenuContext = createContext<SideMenuContextType>({
  isCollapsed: false,
  isMobile: false,
  setIsCollapsed: () => {},
  setIsMobile: () => {}
});

export const useSideMenu = () => useContext(SideMenuContext);

export const SideMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const lsIsCollapsed = localStorage.getItem('menu_is_collapsed') === 'true' ? true : false;
    const [isCollapsed, setIsCollapsed] = useState(lsIsCollapsed);
    const [isMobile, setIsMobile] = useState(false);

    return (
        <SideMenuContext.Provider value={{ isCollapsed, isMobile, setIsCollapsed, setIsMobile }}>
        {children}
        </SideMenuContext.Provider>
    );
};